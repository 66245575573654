import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import kitchen from "../../asset/img/kitchen.svg";
 
import "./banner.scss"
 
import bathroom from "../../asset/img/bathroom.png"
import home from "../../asset/img/home.png"
import condo from "../../asset/img/condo.png"
import basement from "../../asset/img/basement.png"
import bedroom from "../../asset/img/bedroom.png"


import { AiFillPhone } from 'react-icons/ai';
const RemBanner = (props) => {
  return (
    <div className="services-banner"
    style={{'backgroundImage':` linear-gradient(
      to right,
      rgba(14, 105, 167, 0.98) 35%,
      rgba(255, 255, 255, 0.3)
    ), url(${props.img})`}}
    
    >
    <div className="container mt-auto">
      <div className="row">
        <div className="col-md-6">
          <h1>{props.heading}</h1>
          <p>
            {props.content}
          </p>
        </div>
      </div>
    </div>

    <div className="bottom mt-auto">
      <div className="container d-flex justify-content-between align-items-center">
        <ul>
          <li>
            <Link to="/remodeling-and-renovation/kitchen-remodeling">
              <img src={kitchen} alt="Kitchen Remodeling" />
              <span> Kitchen Remodeling</span>
            </Link>
          </li>
          <li>
            <Link to="/remodeling-and-renovation/bathroom-remodeling">
              <img src={bathroom} alt="Bathroom Remodeling" />
              <span> Bathroom Remodeling</span>
            </Link>
          </li>
          <li>
            <Link to="/remodeling-and-renovation/home-remodeling">
              <img src={home} alt="Home Remodeling" />
              <span>Home Remodeling</span>
            </Link>
          </li>
          <li>
            <Link to="/remodeling-and-renovation/condo-remodeling">
              <img src={condo} alt="condo remodeling" />
              <span>Condo Remodeling</span>
            </Link>
          </li>
          <li>
            <Link to="/remodeling-and-renovation/basement-remodeling">
              <img src={basement} alt="basement remodeling" />
              <span> Basement Remodeling</span>
            </Link>
          </li>
          <li>
            <Link to="/remodeling-and-renovation/master-suite-remodeling">
              <img src={bedroom} alt="master-suite-remodeling" />
              <span>
              Master Suit Remodeling <i className="fas fa-closed-captioning"></i>
              </span>
            </Link>
          </li>
          <li className='d-md-none' >
            <a href="tel:919-373-2882"><AiFillPhone className='banner-phone' /> </a>
          </li>
        </ul>

        <NavLink to="/contact" className="contact">
          Contact Us
        </NavLink>
      </div>
    </div>
  </div>
  )
}

export default RemBanner