import React, { useEffect } from "react";
import "./duct.scss";
import bannerImg from "../../../asset/img/services/duct-dummy.webp";
import "./duct.scss";
import ReBanner from "../../../components/service-banner/ReBanner";
import Sidebar from "../../../components/sidebar/Sidebar";
const Duct = () => {
  const heading = "Air Duct Cleaning Services";
  const content = `Top Dog Contractors is a full-service air duct cleaning company that provides cleaning services for residential and commercial HVAC systems. Our services include air duct cleaning, dryer vent cleaning, furnace cleaning, and HVAC mold removal. We are committed to providing our customers with the best air duct cleaning services available.`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
                Home Air Duct Cleaning — Cleaner, Fresher, Safer Home
              </h1>

              <p>
                Most people don’t give the ductwork in their home a second
                thought, unless they have a problem. Unfortunately, many
                homeowners have a ductwork problem they can see — millions of
                unseen contaminants and particles floating through the air they
                breathe.
              </p>

              <p>
                Because HVAC systems grow dirtier with time and use, air duct
                cleaning services are essential for improving and maintaining
                the efficiency of your system and your indoor air quality. This
                may have you wondering, “where’s the best air duct cleaning near
                me?” The good news is, you can find a Top Dog Contractors service
                near location close to where you live. Our HVAC experts
                understand the importance of residential air duct cleaning and
                are committed to helping you maintain excellent air quality in
                your home.
              </p>

              <div className="cost">
                <p>A prompt response using the</p>
                <h1>Top Dog Contractors can save 10-30%</h1>
                <p>on Air Duct Cleaning costs.</p>
              </div>

              <div className="side-feature mt-3">
                <h6>What You Can Do to keep your system running​ ​</h6>
                <ul>
                  <li>
                    <b>Routine Air Filter Changes </b> - Depending on the
                    quality of the filter and the impact on your home’s indoor
                    air (dust, pet hair, allergens, etc.), plan on changing out
                    the air filter every one to three months.
                  </li>
                  <li>
                    <b>Frequent Dusting and Vacuuming</b> – Regular home
                    cleaning can lessen the burden on your air ducts and
                    filters, reducing the frequency for duct cleaning services.
                  </li>
                  <li>
                    <b>Keep Air Supply Vents Open </b> – Opening all the air
                    vents in your home improves indoor airflow and prevents dust
                    and debris from collecting in a closed duct.
                  </li>
                  <li>
                    <b>Indoor Air Purifiers</b> – Indoor air purifiers trap and
                    remove allergens and dust before it enters your air ducts.
                    This also helps reduce the need for frequent air duct
                    cleaning.
                  </li>
                  <li>
                    <b>Reduce Indoor Humidity</b> - Dust and allergens stick to
                    moist surfaces in your home and ducts. Running the bathroom
                    fan when you shower and the kitchen exhaust fan when cooking
                    helps lower indoor humidity.{" "}
                  </li>
                </ul>
              </div>

            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
             <Sidebar/>
            </div>

            {/* <div className="col-md-8 mt-3">
              <h4>Clean Ducts Are Essential for Health and Efficiency </h4>
              <p>
                A service professional may recommend duct cleaning services
                during a scheduled AC maintenance appointment. Dirty ductwork
                can impact your system’s air quality as it heats and cools your
                home. As dust and debris build in your system, they can block
                passages, making it difficult for air to travel through. In
                addition, clogged air filters and ducts force your air
                conditioner and heater to work twice as hard. The result is poor
                air quality, higher energy bills, and excessive HVAC system
                wear.
              </p>
              <p>
                Debris and dirt can also damage your unit. Unwanted particles
                can travel through sensitive parts of HVAC machinery, causing
                clogs, excessive wear, and early breakdowns. At the same time,
                these particles enter your home, compromising your family’s
                comfort and health. Residential air duct cleaning maximizes your
                unit’s longevity and improves the air quality in your home.
              </p>
              <p>
                Many factors can contribute to the gradual breakdown of your air
                ducts—from daily wear to improper installation and prolonged
                exposure to moisture or contaminants. Professional residential
                HVAC cleaning can improve your home’s air quality, rein in your
                utility bills, and extend the lifespan of your heating and
                cooling system.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
                Our HVAC service professionals uphold the highest level of
                workmanship and quality. We deliver excellent duct cleaning
                services and repairs for homeowners across the country, and have
                done so since 1992. We set the industry standard for in-depth
                training and professionalism. Each staff member is fully trained
                on the best HVAC cleaning, service, repair, and replacement
                practices. Each Aire Serv team member takes pride in delivering
                outstanding customer service. When you schedule a residential
                HVAC cleaning with us, you can breathe easier knowing we handle
                your service, repair, or installation the right way. Call us any
                time at
                <a href="tel:919-373-2882"> 919-373-2882</a>, and we’ll be there
                shortly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Duct;
