import React, {useEffect} from "react";
import "./insurance.scss";
import { NavLink } from "react-router-dom";
const Insurance = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container py-5 mt-5">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="content mt-5">
            <h1>INSURANCE CLAIMS EXPERTS</h1>

            <p>
              At Top Dog Contractors Services we provide rapid and superior
              disaster recovery and reconstruction services after{" "}
              <NavLink to="/restoration-and-cleaning/water-damage-restoration">
                water damage
              </NavLink>{" "}
              ,{" "}
              <NavLink to="/restoration-and-cleaning/fire-damage-restoration">
                {" "}
                fire damage
              </NavLink>
              , <NavLink to="">storms</NavLink> and other emergencies. Our
              customers depend on us to provide the guidance, expertise and
              personalized care needed to ensure life gets back to normal,
              quickly. Our company responds to property damage emergencies
              ranging from small residential disasters to large loss{" "}
              <NavLink to="/commercial-services">
                commercial restoration
              </NavLink>{" "}
              projects. We have established relationships with major insurance
              companies and commercial clients, as well as individual
              homeowners.
            </p>

            <img
              src={require("../../asset/img/insurance.webp")}
              alt="topdog insurance"
              className="img-fluid my-4"
            />

            <h2>5 Steps to Making a Property Damage Insurance Claim</h2>

            <p>
              Making a property damage insurance claim with Top Dog Contractors
              Services is easier than you think. After receiving your initial
              service request, an estimator or project manager will visit your
              property to determine the scope of work and estimated repair cost
              for cleanup and restoration of the property damage. To help you
              understand your role and ours in the claim process, we have
              outlined the 5 step process, from reporting your discovery of
              property damage to closing out your claim.
            </p>

            <h3>Step 1 – Report Your Claim</h3>

            <p className="ms-3">
              <b>What you do:</b> Contact Top Dog Contractors Services to speak
              with one of our claims experts and describe your property damage
              (cause of damage, affected areas, if emergency services are
              necessary).
            </p>
            <p className="ms-3">
              <b>What we do:</b> We will schedule an appointment for an
              estimator to inspect your property within 1 hour. If emergency
              service is required, we will dispatch trained technicians to begin
              the recovery process right away. ​
            </p>

            <h3>Step 2 – Document Damaged Property</h3>

            <p className="ms-3">
              <b>What you do:</b> An estimator will inspect the damage at your
              property. The estimator will also take photographs and
              measurements to provide your insurance company with details of the
              property damage.
            </p>
            <p className="ms-3">
              <b>What we do:</b> During our visit, describe the event regarding
              the property damage. Please let us know about any specific
              concerns or questions you have about the cleanup and restoration
              process. If emergency service is required, our team will take
              immediate action to minimize further property damage.
            </p>

            <h3>Step 3 – Establish Repair Costs</h3>

            <p className="ms-3">
              <b>What you do:</b> We will guide you through the process of
              contacting your insurance company to report the property damage.
              We will brief your adjuster regarding details of your property
              damage and the scope of cleanup and repair services. Our estimator
              can provide your adjuster with a detailed estimate for
              full-service restoration and repairs which includes both emergency
              cleanup services and repair services. Your adjuster will review
              our estimates including before and after photos of the property
              damage. Once your adjuster has approved our estimate, we will
              arrange for the work to begin.
            </p>
            <p className="ms-3">
              <b>What we do:</b> Your insurance company will ask you to describe
              events regarding the property damage. They will also require you
              to provide a detailed estimate from a reputable and certified
              restoration company. You will receive various forms from your
              adjuster - please complete these forms and return them promptly to
              not cause any delay to the damage restoration process.
            </p>

            <h3>Step 4 – Start the Recovery Process</h3>

            <p className="ms-3">
              <b>What you do:</b> We can begin the damage restoration service
              right away. As a full-service restoration company, by working with
              Top Dog Contractors Services you eliminate the hassle of hiring a
              separate company for reconstruction services. This will save time
              and money while reducing the number of providers involved in the
              property damage process. For large property claims, we may require
              a deposit before repairs can begin. Your claim specialist and
              adjuster can provide details specific to your situation.
            </p>
            <p className="ms-3">
              Note: As mentioned in Step 1 - if any emergency cleanup service
              was required (flood water removal, fire damage debris cleanup or
              temporary board-up service) we will have already dispatched a crew
              to provide emergency services at your property.{" "}
            </p>
            <p className="ms-3">
              <b>What we do:</b> Note: As mentioned in Step 1 - if any emergency
              cleanup service was required (flood water removal, fire damage
              debris cleanup or temporary board-up service) we will have already
              dispatched a crew to provide emergency services at your property.
            </p>

            <h3> Step 5 – Repairs are Completed and Your Claim is Closed</h3>

            <p className="ms-3">
              <b>What you do:</b> Your project manager and the insurance
              adjuster will call you and discuss your experience throughout the
              claim process. Once you are satisfied that everything has been
              restored to a pre-loss condition, we will collect any balance
              owing and we will close the claim.
            </p>
            <p className="ms-3">
              <b>What we do:</b> Discuss your claim experience with your
              adjuster and authorize them to close the claim once you are
              satisfied with the work that has been completed by us. Keep all
              records of claim-related transactions and activities. ​
            </p>

            <h2 className="mt-3">Why Select Us</h2>

            <p>
              As a full-service disaster recovery company specializing in
              property damage insurance claims, we handle the project from start
              to finish. Our goal is to help you by streamlining the claims
              process while minimizing costs to achieve peace of mind knowing
              that your property is in good hands. ​
            </p>
            <p>
              As your emergency service provider, we promise to consistently
              offer premium quality restoration services while providing an
              extraordinary customer experience. We are always available to
              provide the guidance, expertise and personalized care needed to
              ensure your life gets back to normal, quickly. Our trained
              technicians work by your side providing efficient property damage
              restoration services with expert guidance.
            </p>

            <div className="links">
              <a href="tel:919-373-2882">Call 919-373-2882</a>
               
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
