import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import banner_2 from "../../asset/img/banner-2.png";

import dogBanner from "../../asset/img/dogBanner.jpg";
const Dog = (props) => {
  const location = useLocation();

  let dogImg, link, fullDog;
  if (location.state) {
      dogImg = location.state.dog[0].image;
      link = location.state.dog[0].link;
      fullDog = location.state.dog[0];
  } else {
    dogImg = dogBanner;
     link = "";
  }

  console.log("dog", dogImg);

 

  const [total, setTotal] = useState(0);
  const [dogs, setDogs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const threeItems = dogs?.slice(0, 3);

   //if I have full dog data I want to replace dogs first index with fullDog
 

   if(fullDog){
    dogs[0] = fullDog;     
   }

     

  const Banner = styled.div`    
  min-height: 28vh;
  position: relative;  
  background-color: #000;
   margin-top: 6.5rem;
  background-image: url(${banner_2 });
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 @media all and (max-width: 992px) {
  margin-top: 3rem;
 }

    

   `;

  const getDogs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://shelter.up.railway.app/api/get-pets-data/${page}`
      );
      setLoading(false);
      if (data.success) {
        setDogs(data.dogs);
        console.log("data", data?.dogs);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error);
      console.log("something went wrong");
    }
  };

  const totalDogs = async () => {
    try {
      const { data } = await axios.get(
        `https://shelter.up.railway.app/api/get-total-pets`
      );
      if (data.success) {
        setTotal(data?.count);
        console.log("count", data?.count);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://shelter.up.railway.app/api/get-pets-data/${page}`
      );

      console.log("page", page);

      setLoading(false);
      if (data.success) {
        setDogs([...dogs, ...data?.dogs]);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error);
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);

  useEffect(() => {
    getDogs();
    totalDogs();
  }, []);

  return (
    <>
      <Banner>
         
        
      </Banner>

      {/* <div className="featured-dogs">
        <div className="container">
          <div className="row pt-3">
            <h1 className="fw-bold">Featured Dogs</h1>

            {threeItems.length > 0 ? (
              threeItems.map((item) => (
                <div className="col-md-6 col-lg-4 mt-4" key={item._id}>
                  <div className="dog-card">
                    <div className="dog-image">
                      <a target="_blank" href={item?.link}>
                        <img
                          src={`${item?.image}`}
                          alt={item?.name}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="dog-info">
                      <h3 className="fw-bold">{item?.name}</h3>
                      <div className="d-flex justify-content-between align-content-center mt-4">
                        <ul>
                          <li>{item?.age}</li>
                          <li>{item?.gender}</li>
                        </ul>
                        <div className="size">
                          
                        </div>
                      </div>
                    </div>
                    <div className="link">
                      <a
                        href={item?.link}
                        className="link-bottom"
                        target="_blank"
                      >
                        <AiOutlineEye className="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="notfound mt-5">
                <h1 className="text-center">Data Not Found</h1>
              </div>
            )}
          </div>
        </div>
      </div> */}

      <div className="featured-dogs">
        <div className="container">
          <div className="row pt-3">
            <h1 className="fw-bold">Adoptable dogs</h1>

            {dogs.length > 0 ? (
              dogs.map((item) => (
                <div className="col-md-6 col-lg-3 mt-5" key={item._id}>
                  <div className="dog-card">
                    <div className="dog-image">
                      <a target="_blank" href={item?.link}>
                        <img
                          src={`${item?.image}`}
                          alt={item?.name}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="dog-info">
                      <h3 className="fw-bold">{item?.name}</h3>
                      <div className="d-flex justify-content-between align-content-center mt-4">
                        <ul>
                          <li>{item?.age}</li>
                          <li>{item?.gender}</li>
                        </ul>
                        <div className="size">
                          {/* <p className="m-0">{item?.size}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="link">
                      <a
                        href={item?.link}
                        className="link-bottom"
                        target="_blank"
                      >
                        <AiOutlineEye className="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="notfound mt-5">
                <h1 className="text-center">Data Not Found</h1>
              </div>
            )}
          </div>

          <div className="row mt-5 ">
            <div className="col-12">
              {dogs.length > 0 && dogs.length < total ? (
                <button
                  className="load-more"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage((prevPage) => prevPage + 1);
                  }}
                >
                  {loading ? "Loading..." : "Load More"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dog;
