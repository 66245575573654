import React, { useEffect } from "react";

import bannerImg from "../../../asset/img/services/storm-dummy.webp";
import "./storm.scss";
import ReBanner from "../../../components/service-banner/ReBanner";

import { stormDamage } from "../../../utils/service";
import Sidebar from "../../../components/sidebar/Sidebar";

const Strom = () => {
  const heading = "24/7 STORM DAMAGE RESTORATION";
  const content =
    "Top Dog Contractors respond to calls for help 24/7, 365 days a year, with the latest technology and more resources and expertise than can be found anywhere else. Recover, reconstruct, and restore your property faster";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">STORM DAMAGE RESTORATION</h1>

              <p>
                When a natural disaster or regional emergency strikes, the
                logistical challenges can be overwhelming for those involved in
                relief efforts and restoration operations. Often, large numbers
                of workers are temporarily relocated, requiring provisional
                support such as housing, food, water, and sanitary facilities.{" "}
                <b>Top Dog Contractors Storm Services</b> can help with these
                challenges.
              </p>

              <p>
                As storm frequency and intensity increase around the United
                States, property owners need a name they can trust to help build
                back their homes and livelihoods. Top Dog Contractors is the
                premier emergency restoration company in the nation, vetted by
                millions of home and business owners and recommended first by
                every insurance agency in the industry.{" "}
                <b>Top Dog Contractors Storm Services</b>respond to calls for
                help 24/7, 365 days a year, with the latest technology and more
                resources and expertise than can be found anywhere else.
                Recover, reconstruct, and restore your property faster and
                stronger than before after an extreme weather event by calling
                our home offices at <a href="tel:919-373-2882">919-373-2882</a>,
                and a Top Dog Contractors's team near you will be on-site
                shortly.
              </p>

              <div className="cost">
                <p>A prompt response using the</p>
                <h1>Top Dog Contractors can save 10-30%</h1>
                <p>on Storm damage claim costs.</p>
              </div>

              <div className="side-feature mt-3">
                <h6>Storm Damage Restoration Services​ ​</h6>
                <ul>
                  <li>
                    {" "}
                    <b>Cleanup-</b> Storm damage services include debris removal
                    no matter how large the scale of the disaster.{" "}
                  </li>
                  <li>
                    <b>Fire</b> – Ice, water, or wind could cause a fire to your
                    property by knocking down utility poles or shorting your
                    electrical circuits. Top Dog Contractors has the tools to
                    remove the destruction and rebuild and deodorize and
                    sanitize after ash and smoke damage.
                  </li>
                  <li>
                    <b>Mold</b> – Excess moisture after rain and fire
                    extinguishing may make your home or business a breeding
                    ground for mold. Top Dog Contractors uses specialized
                    equipment to test for spores and antimicrobials to eliminate
                    the toxic fungus from doing more damage.
                  </li>
                  <li>
                    <b>Sewage Leak and Standing Water</b> – Storms can cause
                    pipes to burst and other leaks for sewage or water to enter.
                    TheTop Dog Contractors company provides biohazard mitigation
                    to clean up and eliminate the threat of dangerous pathogens
                    and microorganisms that flourish after this kind of damage.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>
              
            </div>

            {/* <div className="col-md-8 mt-3">
              <h4>Recover More Of Your Possessions With Top Dog Contractors </h4>
              <p>
                The benefit of choosing one of the country’s largest and most
                trusted storm damage restoration companies is getting access to
                our immense resources. Top Dog Contractors uses the industry’s
                most advanced technologies and processes and will help you
                recover more of your possessions that would otherwise be lost.{" "}
              </p>
              <p>
                Top Dog Contractors storm damage repair will rebuild your home or
                business from cracks in the foundation to the roof being blown
                off if necessary, but we can also restore many “soft items” of
                sentimental or monetary value. Items like clothing, sports
                equipment, and leather goods compromised by water and mold can
                be cleaned to food-grade quality and restored. We’ll separate
                salvageable items during debris cleanup and send them to the
                nearest Top Dog Contractors Processing Center, where specialized
                detergents and techniques do the job better than traditional dry
                cleaning. Pick up the pieces of your life more efficiently and
                effectively by choosing the storm damage services of your local
                Top Dog Contractors company.
              </p>

              <h4>24/7 Storm Damage Recovery</h4>
              <p>
                {" "}
                Top Dog Contractors works the same hours as storms and
                catastrophes. Report your need for the most comprehensive and
                trusted storm damage restoration services 24/7, 365. We’ll bring
                the industry’s most extensive resources to bear on your storm
                damage repair project, working quickly to mitigate any problems
                and fully rebuild your property stronger than ever. You’ll be
                able to recover more of your possessions that have been damaged
                and get back to life as usual faster than with any other
                emergency restoration company in the business. Your local Topdog
                Restoration office will also negotiate a claim on your behalf
                with your insurance company, so you have one less thing to worry
                about. Call for storm damage repair any time at{" "}
                <a href="tel:919-373-2882">919-373-2882</a>, and we’ll be there
                shortly.
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <div className="why-choose">
        <div className="container">
          <h1>Why Choose Top Dog Contractors</h1>
          <p>
            Every minute counts. We're here when it matters most. Here are a few
            steps to restoring your property.
          </p>
          <div className="row pt-3">
            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Triage</h4>
                <p>
                  Within minutes of calling, our team responds and is ready to
                  work.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Mitigate</h4>
                <p>
                  We stop the damage with industry-leading tools and experience.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Restore</h4>
                <p>
                  Our certified professionals are skilled in restoring and
                  protecting your location.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Back Safe & Sound</h4>
                <p>You will be back to your property in no time.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="banner-background white py-5">
        <div className="container">
          <h1 className="mb-4">FIRE DAMAGE PHOTO GALLERY</h1>
          <div className="row">
            {stormDamage.map((item, index) => (
              <div
                className="col-6 col-sm-4-col-md-3 col-lg-3 mt-2"
                key={index}
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="img-fluid map-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Strom;
