import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./nav.scss";
import { GoThreeBars } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { BsArrowRight } from "react-icons/bs";
const Nav = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 100 && !isScrolled) {
      setIsScrolled(true);
    } else if (scrollPosition <= 100 && isScrolled) {
      setIsScrolled(false);
    }
  }, [scrollPosition]);

  const className = isScrolled ? " m-0 ms-2 mujLogoScrol" : "mujLogo m-0 ms-2";

  const openNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <>
      {isNavOpen && (
        <div className="model">
          <div className={isNavOpen ? `model-inner animation` : `model-inner`}>
            <div className="d-flex justify-content-between">
              <div>
                <Link to="/">
                  <img
                    src={require("../../asset/img/logo.png")}
                    className="nav-logo"
                    alt="Top Dog Contractors"
                  />
                </Link>
              </div>

              <div>
                <RxCross2 className="nav-cross" onClick={closeNav} />
              </div>
            </div>

            <div className="list">
              <ul>
                <li>
                  <NavLink to="/" onClick={closeNav}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/restoration-and-cleaning" onClick={closeNav}>
                    Restoration-Cleaning
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/remodeling-and-renovation" onClick={closeNav}>
                  Remodeling-Renovation
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/commercial-services" onClick={closeNav}>
                  Commercial
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/insurance" onClick={closeNav} >Insurance Claims</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={closeNav}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="nav-contact">
              <h5>24 hours Service</h5>
              <a href="tel:919-373-2882">919-373-2882</a>
            </div>
          </div>
        </div>
      )}

      <section className="fixed-top  mujTopNavigation relative">
        <div className="row ">
          <div className="col-lg-2 col-xl-2">
            <div className="logoDiv p-2 py-3">
              <Link to="/">
                <img
                  src={require("../../asset/img/logo.png")}
                  className={className}
                  alt="logoNotFound"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-8 col-xl-8 py-2 d-none d-lg-flex align-items-center ">
            <div className="mujNav ms-auto">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                 

                <li className="dropdown">
                  <NavLink to="/restoration-and-cleaning">
                    Restoration & Cleaning
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/restoration-and-cleaning/water-damage-restoration">
                        <BsArrowRight /> Water Damage Restoration/Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/restoration-and-cleaning/fire-damage-restoration">
                        <BsArrowRight /> Fire Damage Restoration/Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/restoration-and-cleaning/mold-remediation">
                        <BsArrowRight /> Mold Remediation 
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/restoration-and-cleaning/storm-damage-restoration">
                        <BsArrowRight /> Storm Damage Restoration/Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/restoration-and-cleaning/biohazard-cleanup">
                        <BsArrowRight /> Bio-Hazard Cleanup
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/restoration-and-cleaning/air-duct-cleaning">
                        <BsArrowRight /> Air Duct Cleaning
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <NavLink to="/remodeling-and-renovation">
                    Remodeling & Renovation
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/remodeling-and-renovation/kitchen-remodeling">
                        <BsArrowRight /> Kitchen Remodeling/Renovation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/remodeling-and-renovation/bathroom-remodeling">
                        <BsArrowRight /> Bathroom Remodeling/Renovation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/remodeling-and-renovation/home-remodeling">
                        <BsArrowRight /> Whole Home Remodeling/Renovation
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/remodeling-and-renovation/condo-remodeling">
                        <BsArrowRight /> Condo Remodeling/Renovation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/remodeling-and-renovation/basement-remodeling">
                        <BsArrowRight /> Basement Remodeling/Renovation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/remodeling-and-renovation/master-suite-remodeling">
                        <BsArrowRight /> Master Suit Remodeling/Renovation
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to="/commercial-services">
                    Commercial
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/insurance">Insurance Claims</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 d-none d-xl-block p-0">
            <div className="mujContact">
              <h5 className="heading m-0">24 hours Service</h5>
              <h5 className="heading m-0">
                Call <a href="tel:919-373-2882">919-373-2882</a>
              </h5>
            </div>
          </div>

          <div className="d-lg-none">
            <GoThreeBars className="handburger" onClick={openNav} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Nav;
