import React, { useEffect, useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bannerImg from "../../../asset/img/services/bedroom/bd4.webp";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Suit = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

  const heading = "Master Suit Remodeling & Renovation";
  const content =
    "Do you dream of having a luxurious master suite? Separate from the noise and chaos of the rest of the house, a master suite is a tranquil oasis where you can bathe, dress, and relax in peace.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
                Master Suit Remodeling in Greater Triangle Area, North Carolina
              </h1>

              <p>
                Do you dream of having a luxurious master suite? Separate from
                the noise and chaos of the rest of the house, a master suite is
                a tranquil oasis where you can bathe, dress, and relax in peace.
                Choose Top Dog Contractors for your master suite renovation in
                Greater Triangle Area and Beyond
, and we’ll deliver a private place where you can escape
                at the end of a hectic day. Our committed and experienced team
                will oversee the project from start to finish, so you can sit
                back and watch your vision come to life.
              </p>
              <p>
                We offer a complete renovation experience from start to finish,
                with a commitment to outstanding quality and on-time and
                on-budget completion. Our team is dedicated to going above and
                beyond and to guarantee your satisfaction.
              </p>
              <h2>
                <b>
                  For Basement Renovations or Remodeling you can trust in
                  Greater Triangle Area, call{" "}
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>{" "}
                  to get started.
                </b>
              </h2>

              <div className="side-feature mt-3">
                <h6>
                  Master Suit Designing in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Knowledge expertise</li>
                  <li>Honesty and integrity</li>
                  <li>Quality materials and service</li>
                  <li>Timely communication</li>
                  <li>Award-winning designs</li>
                  <li>Superior customer service</li>
                  <li>Complete transparency</li>
                  <li>Professionalism and organization</li>
                  <li>Meticulous attention to detail</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
             <Sidebar/> 
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>Aspects Of A Master Suite Renovation</h5>
                <p>
                  Your master bedroom is far more than just four walls and a
                  ceiling. Here are some of the ways you can customize your
                  master suite and incorporate popular design trends for 2021:
                </p>
                <ul>
                  <li>
                    Build ample closet space, possibly even “his” and “hers”
                    closets
                  </li>
                  <li>
                    Create ceiling and wall designs with paneling, cove
                    lighting, and a built-in headboard
                  </li>
                  <li>
                    Select luxury flooring options, such as plush wall-to-wall
                    carpet or low-maintenance laminate
                  </li>
                  <li>
                    Choose window sizes, styles, and placement for both the
                    master bedroom and bathroom
                  </li>
                  <li>Install new outlets to accommodate electronic devices</li>
                  <li>
                    Optimize master bedroom lighting with a combination of
                    general overhead lights, dimmable sconces, and bedside
                    reading lamps
                  </li>
                  <li>And More</li>
                </ul>

                <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call{" "}
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a>{" "}
                    to get started.
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/bedroom/bedroom-mockup.jpg")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/bedroom/bd4.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3   ">
              <h1 className="process-heading">
                <span>Master Suite</span> Renovation Process
              </h1>
              <p>
                At Top Dog Contractors, our process focuses on meeting your needs
                and keeping you comfortable throughout the project. A master
                suite renovation can be summed up in three steps:
              </p>
              <ul>
                <li className="mb-3">
                  <b>Understand client expectations:</b> A designer will meet
                  with you to discuss your wants, needs, goals, and design
                  preferences. We’ll make sure our services are the right fit
                  for you before moving forward.
                </li>
                <li className="mb-3">
                  <b>Design and preconstruction:</b> Our team will work closely
                  with you to formulate a flawless design, select materials, and
                  finishes, and prepare a detailed budget. Once you approve,
                  both parties sign a pre-construction agreement.
                </li>
                <li className="mb-3">
                  <b>Sign the contract and begin construction:</b> With all the
                  logistics worked out, work can begin on your new master suite.
                  We’ll keep you informed of our progress every step of the way.
                  A final walk-through ensures you are thrilled with the results
                  before we deem the job complete.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
                Whether your renovation involves one room, basement or the
                entire house, you want a team that boasts keen attention to
                detail and preparedness for any surprises that could arise.
                Approach us with nothing more than a problem that needs solving,
                and we’ll get to work designing and delivering a solution.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                <b>
                  Schedule your consultation with Top Dog Contractors, call
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>
                  to get started.
                </b>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Master Suite Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/bedroom/bd3.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Master Suite Remodeling Gallery</h2>
                  <p>
                    Topdog Master Suite remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd5.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/bedroom/bd9.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Master Suite Remodeling Gallery</h2>
                  <p>
                    Topdog Master Suite remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd8.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd9.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd6.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bedroom/bd7.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Suit;
