import React, {useEffect} from "react";
import "./mold.scss";

import { Link, NavLink } from "react-router-dom";
import { moldDamage } from "../../../utils/service";
import bannerImg from "../../../asset/img/services/mold-main.webp";
import ReBanner from "../../../components/service-banner/ReBanner";
import Sidebar from "../../../components/sidebar/Sidebar";
const Mold = () => {
  const heading = "PROFESSIONAL MOLD REMOVAL SERVICES.";
  const content =
    "Mold remediation prevents the spread of mold by isolating damaged areas, removing affected material and eliminating moisture sources.";



    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <ReBanner img={bannerImg} heading={heading} content={content} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="water-heading-1">MOLD REMOVAL AND REMEDIATION</h1>

              <p>
                Top Dog Contractors Services has extensive experience in
                investigating, removing and remediating mold contamination. Our{" "}
                <a href="https://www.iicrc.org/">IICRC</a> certified personnel
                use state-of-the-art equipment and qualified laboratories to aid
                in identifying and eliminating mold contamination. Hiring an
                experienced and certified remediationcompany is important when
                it comes to safe mold removal. Flooding and water damage in
                carpet, wood, and drywall can lead to mold growth which poses a
                health risk to you and your family. It is important to address
                the source of water intrusion and to safely remove the mold as
                soon as possible.
              </p>
              <p>
                {" "}
                Mold damage can occur for a variety of reasons including but not
                limited to:
              </p>

              <ul>
                <li>Flooding & Water Damage</li>
                <li>Sewer Backup & Sewage Damage</li>
                <li>Construction defects</li>
                <li>Plumbing failures</li>
                <li>Appliance water line or drain line breaks</li>
                <li>Improper maintenance</li>

                <li>Undetected Moisture Retention</li>
                <li>Heavy Rainfall & Storm Damage</li>
                <li>Foundation Wall Seepage</li>
                <li>Attic Condensation</li>
                <li>High Humidity</li>
              </ul>

              <div className="side-feature">
                <h6>
                  Mold Removal and Remediation in Greater Triangle Area, North
                  Carolina
                </h6>
                <ul>
                  <li>Residential and Commercial Services</li>
                  <li>Mold Damage Inspection​</li>
                  <li>Emergency Mold Removal</li>
                  <li>Emergency Containment Enclosures</li>
                  <li>Attic Mold Cleaning and Stain Removal</li>
                  <li>Mold Cleaning and Odour Removal</li>
                  <li>Structural Drying and Dehumidification</li>
                  <li>Cleaning and Decontamination</li>
                  <li>HEPA Air Filtration & Negative Air Equipment</li>
                  <li>Deodorization</li>
                  <li>HVAC Cleaning</li>
                  <li>Environmental Laboratory Services</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <Sidebar/>
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose">
        <div className="container">
          <h1>Why Choose Top Dog Contractors</h1>
          <p>
            Top Dog Contractors PuroClean offers mold remediation services to
            stem the tide of mold, as well as cleaning including addressing the
            source of mold odor to restore your home or business to its pre-loss
            condition. Remediation of mold can be a serious endeavor, and
            PuroClean has the expertise and technology for the job.
          </p>
          <div className="row pt-3">
            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Thorough Cleanup</h4>
                <p>
                  We treat mold-damaged property as if it were our own.
                  PuroClean’s remediation experts use the latest technology to
                  identify both mold and the moisture source that feeds mold
                  growth.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Ongoing Communication</h4>
                <p>
                  We keep you and your insurance agent informed at every step of
                  the cleanup process to ensure everyone involved understands
                  what’s happening and what to expect..
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Industry Certified Technicians</h4>
                <p>
                  Top Dog Contractors's technicians are thoroughly screened,
                  fully insured, and IICRC certified to remediate property
                  damage.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>State-of-the-art technology</h4>
                <p>
                  Top Dog Contractors's mold remediation professionals first
                  identify the extent of the problem, and then work with care
                  and sensitivity to rescue the affected area and restore it to
                  pre-loss conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="process ">
        <div className="container">
          <div className="row">
            <div className="col-md-8 my-4">
              <h4>
                Make Us Your First Call for Mould Removal and Repair Services
              </h4>
              <p>
                Top Dog Contractors Services understands the urgency to quickly
                identify areas of mould damage so that appropriate measures can
                be taken for the mould cleaning and removal process to begin.
                After mould damage has been discovered, our technicians first
                attempt to rescue any contents and personal items that have been
                damaged by mould. Our team of IICRC certified personnel provide
                industry-leading response times and comprehensive assessments
                supported by the use of state-of-the-art moisture detection and
                mould remediation equipment.
              </p>

              <p>
                If you require immediate mould removal services, get the
                professional help you need and the peace of mind you deserve -
                don't hesitate to contact the mould removal experts at Topdog
                Restoration Services. Have questions? Call us today for a free
                estimate{" "}
                <b>
                  <a href="tel:919-373-2882">919-373-2882</a>
                </b>
                .
              </p>
            </div>
            <div className="col-md-4 my-4">
              <img
                src={require("../../../asset/img/services/mold.webp")}
                alt="mold remidiation"
                className="img-fluid w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="banner-background white py-5">
        <div className="container">
          <h1 className="mb-4">FIRE DAMAGE PHOTO GALLERY</h1>
          <div className="row">
            {moldDamage.map((item, index) => (
              <div
                className="col-6 col-sm-4-col-md-3 col-lg-3 mt-2"
                key={index}
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="img-fluid map-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Mold;
