import React, { useEffect } from "react";
 
import { Link, NavLink } from "react-router-dom";
import { BsQuote } from "react-icons/bs";
import service, { remodeling } from "../../../utils/service";
import Banner from "../../../components/service-banner/Banner";
import bannerImg from "../../../asset/img/services/restoration-banenr.jpeg";
import Sidebar from "../../../components/sidebar/Sidebar";
import ReBanner from "../../../components/service-banner/ReBanner";
const Restoration = () => {
  const heading = "MAKE US YOUR FIRST CALL IN AN EMERGENCY.";
  const content =
    "As the frontrunner in customer satisfaction, Top Dog Contractors is a comprehensive restoration company that offers prompt 24/7 emergency services in the Greater Triangle Area.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />

      <div className="topdog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2>We Are Here To Serve You for your Restoration and Cleaining Needs</h2>
              <h4>Top Dog Contractors is here to help</h4>
              <p>Restoration and Cleaning Services is the trusted choice of top insurance companies for fast, professional, and dependable property damage restoration and cleaning. Our full-service company in the Greater Triangle Area and Beyond is well-versed in the insurance claim process and committed to completing repairs and cleaning tasks with urgency. Count on our turnkey recovery and cleaning solutions, along with exceptional customer satisfaction, to meet the needs of both you and your clients.
              </p>
              <p>
              With 24/7 access to our services, you can trust that we'll be there when you need us most. Our experts use the latest technology and techniques to quickly detect and eliminate any mold and moisture sources. By containing the spores, removing mold, and providing thorough cleaning, we can prevent health risks associated with mold growth and ensure a pristine environment for your property.
              </p>

              <div className="review">
                <div className="testimonial">
                  <p className="position-relative">
                    <BsQuote className="quote-left" />
                    Restoration and Cleaning Services went above and beyond in restoring our water-damaged property. Their attention to detail and professionalism were exceptional. Highly recommend their top-notch restoration and cleaning solutions
                    <BsQuote className="quote-right" />
                  </p>
                  <p>[John, Homeowner]</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center align-itmes-center">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>

      <div className="all-services" id="restoration-and-cleaning">
        <div className="container">
        <h4 className="servic-heading-4">Our Services</h4>
          <h1 className="service-heading serivce-page mt-5" >
            Helping You With Your Restoration and Cleaning Needs
          </h1>
          
 

          <div  className="row pt-5">
            {service.map((item, index) => {
              return (
                <div key={index} className="col-md-6 col-lg-4 mb-5">
                  <NavLink to={item.link}>
                    <div className="custom-card">
                      <div className="card-image">
                        <img
                          src={item.img}
                          alt={item.alt}
                          className="img-fluid"
                        />
                      </div>
                      <div className="card-content">
                        <h4>{item.title}</h4>

                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>

     
      <div className="count">
        <div className="container">
          <h1 className="h1">why select us</h1>
          <p className="p">
            Restoration is an IICRC certified restoration company in Greater
            Triangle Area that specializes in restoring residential and
            commercial properties damaged by fire, water, mold, wind and
            vandalism. We are a privately owned full-service general contracting
            company providing construction, renovation and environmental
            services.
          </p>

          <h3 className="h3">Emergency Cleanup and Restoration Services</h3>
          <ul className="count-ul">
            <li>24/7 Emergency Response</li>
            <li>Half Hour Arrival Time</li>
            <li>Advanced Equipment & Techniques</li>
            <li>Professional Communication & Documentation</li>
            <li>Fast Cleanup for Peace of Mind</li>
          </ul>

          <h3 className="h3">Certified, Qualified and Professional</h3>
          <ul className="count-ul">
            <li>Insurance Claim Specialists</li>
            <li>Fast, Friendly and Reliable Service</li>
            <li>IICRC Trained and Certified Technicians</li>
            <li>Patient & Compassionate Staff</li>
            <li>Licensed and Insured</li>
          </ul>

          <p>
            Whether you have experienced property damage due to flooding or
            other
            <Link to="/services/water-damage-restoration">
              water damage
            </Link> , <Link to="/services/fire-damage-restoration">fire</Link> ,
            <Link to="/services/storm-damage-restoration">storm damage</Link> or
            you are concerned about environmental contaminants such as mold or
            asbestos, we are committed to providing prompt emergency restoration
            services 24 hours a day, 7 days a week, 365 days a year.
          </p>
        </div>
      </div>
    </>
  );
};

export default Restoration;
