 
 
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import NotFound from "./pages/notfound/NotFound";
import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import Water from "./pages/services/water/Water";
import Remodeling from "./pages/services/remodeling/Remodeling";
import Restoration from "./pages/services/restoration/Restoration"
import Fire from "./pages/services/fire/Fire";
import Mold from "./pages/services/mold/Mold";
import Biohazard from "./pages/services/biohazard/Biohazard";
import Strom from "./pages/services/strom/Strom";
import Duct from "./pages/services/airduct/Duct";
import Contact from "./pages/contact/Contact";
import Kitchen from "./pages/services/kitchen/Kitchen";
import Bathroom from "./pages/services/bathroom/Bathroom";
import Home from "./pages/services/home/Home";
import Condo from "./pages/services/condo/Condo";
import Basement from "./pages/services/basement/Basement";
import Suit from "./pages/services/suit/Suit";
import Commercial from "./pages/services/commercial/Commercial";
import Insurance from "./pages/insurance/Insurance";
import Dog from "./pages/Dog/Dog";
const App = () => {


 
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/"   element={<Landing />} />
        <Route path="/remodeling-and-renovation"   element={<Remodeling/> }/>
        <Route path="/restoration-and-cleaning"   element={<Restoration/> }/>
        
        <Route path="/contact"   element={<Contact/> } />
        <Route path="/restoration-and-cleaning/water-damage-restoration" exact element={<Water/> } />
        <Route path="/restoration-and-cleaning/fire-damage-restoration"   element={<Fire/> } />
        <Route path="/restoration-and-cleaning/mold-remediation"   element={<Mold/> } />
        <Route path="/restoration-and-cleaning/biohazard-cleanup"   element={<Biohazard/> } />
        <Route path="/restoration-and-cleaning/storm-damage-restoration" exact element={<Strom/> } />
        <Route path="/restoration-and-cleaning/air-duct-cleaning"   element={<Duct/> } />
        <Route path="/remodeling-and-renovation/kitchen-remodeling"   element={<Kitchen/> } />
        <Route path="/remodeling-and-renovation/bathroom-remodeling"   element={<Bathroom/> } />
        <Route path="/remodeling-and-renovation/master-suite-remodeling"   element={<Suit/> } />
        <Route path="/remodeling-and-renovation/home-remodeling"   element={<Home/> } />
        <Route path="/remodeling-and-renovation/condo-remodeling"   element={<Condo/> } />
        <Route path="/remodeling-and-renovation/basement-remodeling"   element={<Basement/> } />
        <Route path="/commercial-services"   element={ <Commercial/> } />
         
        <Route path="/insurance"   element={ <Insurance/> } />
        <Route path="/shelter"   element={ <Dog/> } />



        

        

       

        

        
         
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer/>
    </>
  );
};

export default App;
