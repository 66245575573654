import React, { useEffect } from "react";
import "./biohazard.scss";

import { bioDamage } from "../../../utils/service";
import bannerImg from "../../../asset/img/services/bio-dummy.webp";
import ReBanner from "../../../components/service-banner/ReBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Biohazard = () => {
  const heading = "Biohazard & Virus Cleanup Services";
  const content =
    "Top Dog Contractors professionals are licensed, insured, and certified to remediate biohazard environments safely and in accordance with all applicable laws and standards.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
                Biohazard & Virus Cleanup Services
              </h1>

              <p>
                We understand that some events may be sensitive and must be
                handled with compassion and discretion. We bring a sense of calm
                back to those impacted, whether the loss is in a home,
                commercial property or business office.
              </p>
              <p>
                Biohazard cleanup involves cleaning, sanitizing, and deodorizing
                areas where a traumatic event such as an accident, injury or
                death has occurred. Thus, dealing with biohazards often means
                dealing with blood, human or animal remains, chemical spills,
                and more. Keep in mind that the state is not responsible for
                cleaning up a crime scene. After police investigators collect
                evidence from the scene, it is up to the property owner to seek
                biohazard cleaning services. A professional cleanup team can
                arrive onsite only after the police have gathered enough
                evidence related to the crime.
              </p>

              <h4>Common Biohazard Scenarios</h4>

              <ul>
                <li>Crime or homicide scenes</li>
                <li>Suicide or death</li>
                <li>Blood and bodily fluid</li>
                <li>Hoarding scenes</li>
                <li>Animal waste or remains</li>
                <li>Chemical hazards</li>

                <li>Coronavirus contamination</li>
              </ul>

              <div className="side-feature">
                <h6>
                  Biohazard & virus Cleanup Greater Triangle Area, North
                  Carolina
                </h6>
                <ul>
                  <li>Blood and Bodily Fluid Remediation</li>
                  <li>Virus Decontamination and Protection​</li>
                  <li>Sewage Cleanup and Sanitation</li>
                  <li>Deodorization and Odor Control</li>
                  <li>Crime Scene Cleanup</li>
                  <li>Hoarding Cleanup</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose">
        <div className="container">
          <h1>Why Choose Top Dog Contractors</h1>
          <p>
            Top Dog Contractors professionals are licensed, insured, and
            certified to remediate biohazard environments safely and in
            accordance with all applicable laws and standards. We understand
            that some events may be sensitive and must be handled with
            compassion and discretion. We bring a sense of calm back to those
            impacted, whether the loss is in a home, commercial property or
            business office.
          </p>
          <div className="row pt-3">
            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Top Dog Contractors Program</h4>
                <p>
                  {" "}
                  Top Dog Contractors is the best way to deep clean public areas
                  and help stop the spread of viruses. The process uses
                  EPA-registered products to minimize the risk of pathogens
                  spreading. Call us to help you create a clean environment.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>High Standards</h4>
                <p>
                  Top Dog Contractors uses Environmental Protection
                  Agency-registered, safe products to provide up to three months
                  of protection that prevents contamination and minimizes the
                  risk of spreading pathogens.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Compassionate</h4>
                <p>
                  We understand the trauma you and your loved ones are facing.
                  PuroClean rescues properties with compassion and care.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Innovative</h4>
                <p>
                  Top Dog Contractors is the best way to sanitize public areas.
                  Our system helps stop the spread of pathogen-based illnesses,
                  such as Influenza and the Coronavirus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4>Our Biohazard Cleanup Process</h4>
              <ul>
                <li>
                  First of all, the remediation crew identifies the affected
                  areas and determines the scope of the biohazard cleanup. Once
                  a plan is in place, remediation efforts begin as quickly as
                  possible.
                </li>
                <li>
                  Then, technicians take steps to maintain their safety and that
                  of other individuals near the cleanup site.
                </li>
                <li>
                  Afterward, they remove all potentially infectious materials
                  from the site.
                </li>
                <li>
                  Hazardous medical waste, such as spinal fluid, peritoneal
                  fluid, amniotic fluid, and other bodily fluids, is handled
                  following OSHA regulations. It’s then sent to a licensed
                  hazardous medical waste incinerator.
                </li>
                <li>
                  Technicians remove porous materials like fabric and carpeting
                  following industry standards and state regulations.
                </li>
                <li>
                  Non-hazardous materials are either removed or decontaminated
                  and sanitized if they are salvageable.
                </li>
                <li>
                  After cleanup and sanitization, technicians deodorize the
                  affected areas. Walls and flooring may be sealed to cover any
                  remaining stains from the incident.
                </li>
                <li>
                  Removing contaminated materials, then cleaning and sanitizing
                  is often enough to control odors. In some cases, the crew will
                  use hydroxyls or ozone to ensure the odor is eliminated.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="banner-background white py-5">
        <div className="container">
          <h1 className="mb-4"> BIOHAZARD CLEANUP GALLERY</h1>
          <div className="row">
            {bioDamage.map((item, index) => (
              <div
                className="col-6 col-sm-4-col-md-3 col-lg-3 mt-2"
                key={index}
              >
                <img
                  src={item.img}
                  className="img-fluid map-image"
                  alt={item.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Biohazard;
