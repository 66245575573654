import React, { useEffect, useState } from "react";
import "./kitchen.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";  
import bannerImg from "../../../asset/img/services/kitchen-banner.webp";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Kitchen = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
   
  const heading = "Kitchen Remodeling & Renovation";
  const content =
    "The kitchen is the centerpiece of the home and, as such, should be a space that’s as welcoming and beautiful as it is functional and thoughtfully designed.​";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">Custom Kitchen Remodeling & Renovation</h1>

              <p>
                The kitchen is the centerpiece of the home and, as such, should
                be a space that’s as welcoming and beautiful as it is functional
                and thoughtfully designed. If the kitchen in your Greater Triangle Area and Beyond
 home
                feels cramped, cluttered, or simply outdated, Top Dog Contractors
                can help.
              </p>
              <p>
                Committed to complete client satisfaction and delivering a
                stress-free, streamlined process, Top Dog Contractors is the only
                team you need to revitalize your culinary space.
              </p>
              <p>
                We go above and beyond to ensure your dream kitchen is carefully
                crafted to your precise expectations without going over budget.
              </p>

              <div className="side-feature mt-3">
                <h6>
                  Kitchen Remodeling in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Backsplash tiles or accent walls</li>
                  <li>Custom cabinetry</li>
                  <li>Custom countertops</li>
                  <li>Flooring replacement</li>
                  <li>Kitchen bump-out or layout alterations</li>
                  <li>Plumbing fixture updates</li>
                  <li>Upgraded appliances</li>
                  <li>Window or door replacement</li>
                  <li>And more</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>

             
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>Stress-Free, Streamlined Kitchen Remodeling</h5>
                <p>
                  At Top Dog Contractors, we believe transparency is the key to
                  100% customer satisfaction. We’re committed to providing a
                  stress-free, streamlined renovation process from start to
                  finish. Here’s what to expect when working with our
                  award-winning design team:
                </p>
              </div>
              <div className="mb-3">
                <h5>Consultation</h5>
                <p>
                  It starts with an on-site meeting to discover your needs,
                  wants, goals, and design preferences. If our team seems like
                  the right fit, we’ll move on to the next phase.
                </p>
              </div>

              <div className="mb-3">
                <h5>Design</h5>
                <p>
                  Our expert designers will outline the full scope of your
                  project, including drawings, 3D renderings, and concept art.
                  We’ll begin selecting materials and finishes and prepare a
                  detailed budget for your approval before the build begins.
                </p>
              </div>
              <div className="mb-3">
                <h5>Build</h5>
                <p>
                  Once the preconstruction agreement is signed, we’ll plan the
                  logistics of your kitchen renovation and start building. We
                  keep you informed every step of the way, providing a final
                  walkthrough to ensure your total satisfaction.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/kitchen/kitchen-mockup.webp")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/kitchen/priceguide-img.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3 p-2 px-4 pt-xl-5">
              <h1 className="process-heading  pt-xl-5">
                <span>Realize</span> your kitchen remodeling dreams with Topdog
                Restoration
              </h1>

              <h1 className="red fw-bold">
                <span>WE</span>
              </h1>
              <div className="d-flex ">
                <h3 className="pe-5">DESIGN</h3>
                <h3 className="pe-5">REFINE</h3>
                <h3 className="red pe-5">AND</h3>

                <h3>BUILD</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
              Whether your renovation involves one room or the entire house, you want a team that boasts keen attention to detail and preparedness for any surprises that could arise. Approach us with nothing more than a problem that needs solving, and we’ll get to work designing and delivering a solution.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call 
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a> 
                    to get started.
                  </b>
                </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Kitchen Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/kitchen/k1.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Kitchen Remodeling Gallery</h2>
                  <p>
                    Topdog Kitchen remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/kitchen/k1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k3.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k6.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/kitchen/k7.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Kitchen Remodeling Gallery</h2>
                  <p>
                    Topdog Kitchen remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/kitchen/k13.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k12.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k11.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k10.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k8.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k9.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kitchen;
