import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import water from "../../asset/img/water.svg";
import mold from "../../asset/img/virus.svg";
import fire from "../../asset/img/fire.svg";
import storm from "../../asset/img/storm-weather.svg";
import cleaning from "../../asset/img/cleaning.svg";
import bio from "../../asset/img/biohazard.svg";
import "./banner.scss"
import { AiFillPhone } from 'react-icons/ai';
const ReBanner = (props) => {
  return (
    <div className="services-banner"
    style={{'backgroundImage':` linear-gradient(
      to right,
      rgba(14, 105, 167, 0.98) 35%,
      rgba(255, 255, 255, 0.3)
    ), url(${props.img})`}}
    
    >
    <div className="container mt-auto">
      <div className="row">
        <div className="col-md-6">
          <h1>{props.heading}</h1>
          <p>
            {props.content}
          </p>
        </div>
      </div>
    </div>

    <div className="bottom mt-auto">
      <div className="container d-flex justify-content-between align-items-center">
        <ul>
          <li>
            <Link to="/restoration-and-cleaning/water-damage-restoration">
              <img src={water} alt="water restoration" />
              <span> Water Restoration</span>
            </Link>
          </li>
          <li>
            <Link to="/restoration-and-cleaning/fire-damage-restoration">
              <img src={fire} alt="water restoration" />
              <span> Fire Restoration</span>
            </Link>
          </li>
          <li>
            <Link to="/restoration-and-cleaning/mold-remediation">
              <img src={mold} alt="water restoration" />
              <span>Mold Remediation</span>
            </Link>
          </li>
          <li>
            <Link to="/restoration-and-cleaning/storm-damage-restoration">
              <img src={storm} alt="water restoration" />
              <span>Storm Recovery</span>
            </Link>
          </li>
          <li>
            <Link to="/restoration-and-cleaning/biohazard-cleanup">
              <img src={bio} alt="water restoration" />
              <span> Bio-Hazard Cleanup</span>
            </Link>
          </li>
          <li>
            <Link to="/restoration-and-cleaning/air-duct-cleaning">
              <img src={cleaning} alt="water restoration" />
              <span>
                Air Duct <i className="fas fa-closed-captioning"></i>
              </span>
            </Link>
          </li>
          <li className='d-md-none' >
            <a href="tel:919-373-2882"><AiFillPhone className='banner-phone' /> </a>
          </li>
        </ul>

        <NavLink to="/contact" className="contact">
          Contact Us
        </NavLink>
      </div>
    </div>
  </div>
  )
}

export default ReBanner