import water from "../asset/img/restoration/basement.webp";
import mold from "../asset/img/restoration/molding.webp";
import fire from "../asset/img/restoration/fire.webp";
import storm from "../asset/img/restoration/storm.webp";
import biohazard from "../asset/img/restoration/biohazard.webp";
import airduct from "../asset/img/restoration/pollution.webp";
 
import d1 from "../asset/img/services/water-damage/d-1.webp"
import d2 from "../asset/img/services/water-damage/d-2.webp"
import d3 from "../asset/img/services/water-damage/d-3.webp"
import d4 from "../asset/img/services/water-damage/d-4.webp"
import d5 from "../asset/img/services/water-damage/d-5.webp"
import d6 from "../asset/img/services/water-damage/d-6.webp"
import d7 from "../asset/img/services/water-damage/d-7.webp"
import d8 from "../asset/img/services/water-damage/d-8.webp"
import d9 from "../asset/img/services/water-damage/d-9.webp"
import d10 from "../asset/img/services/water-damage/d-10.webp"
import d11 from "../asset/img/services/water-damage/d-11.webp"
import d12 from "../asset/img/services/water-damage/d-12.webp"

import f1 from "../asset/img/services/fire-damage/f1.webp"
import f2 from "../asset/img/services/fire-damage/f2.webp"
import f3 from "../asset/img/services/fire-damage/f3.webp"
import f4 from "../asset/img/services/fire-damage/f4.webp"
import f5 from "../asset/img/services/fire-damage/f5.webp"
import f6 from "../asset/img/services/fire-damage/f6.webp"
import f7 from "../asset/img/services/fire-damage/f7.webp"
import f8 from "../asset/img/services/fire-damage/f8.webp"
import f9 from "../asset/img/services/fire-damage/f9.webp"
import f10 from "../asset/img/services/fire-damage/f10.webp"


import m1 from "../asset/img/services/mold-damage/m1.webp"
import m2 from "../asset/img/services/mold-damage/m2.webp"
import m3 from "../asset/img/services/mold-damage/m3.webp"
import m4 from "../asset/img/services/mold-damage/m4.webp"
import m5 from "../asset/img/services/mold-damage/m5.webp"
import m6 from "../asset/img/services/mold-damage/m6.webp"

import m7 from "../asset/img/services/mold-damage/m7.webp"
import m8 from "../asset/img/services/mold-damage/m8.webp"
import m9 from "../asset/img/services/mold-damage/m9.webp"
import m10 from "../asset/img/services/mold-damage/m10.webp"
import m11 from "../asset/img/services/mold-damage/m11.webp"


import b1 from "../asset/img/services/biohazard/b1.webp"
import b2 from "../asset/img/services/biohazard/b2.webp"
import b3 from "../asset/img/services/biohazard/b3.webp"
import b4 from "../asset/img/services/biohazard/b4.webp"
import b5 from "../asset/img/services/biohazard/b5.webp"
import b6 from "../asset/img/services/biohazard/b6.webp"

import s1 from "../asset/img/services/storm-damage/s1.webp"
import s2 from "../asset/img/services/storm-damage/s2.webp"
import s3 from "../asset/img/services/storm-damage/s3.webp"
import s4 from "../asset/img/services/storm-damage/s4.webp"
import s5 from "../asset/img/services/storm-damage/s5.webp"
import s6 from "../asset/img/services/storm-damage/s6.webp"
import s7 from "../asset/img/services/storm-damage/s7.webp"
import s8 from "../asset/img/services/storm-damage/s8.webp"
import s9 from "../asset/img/services/storm-damage/s9.webp"
import k1 from "../asset/img/services/kitchen/k1.webp"
import bathroom from "../asset/img/services/bathroom/b1.webp"
import home from "../asset/img/services/home/in1.webp"
import condo from "../asset/img/services/condo/co2.webp"
import basement from "../asset/img/services/basement/bs3.webp"
import bedroom from "../asset/img/services/bedroom/bd6.webp"

export const  stormDamage=[
  {
    id: 1,
    img: s1,
    alt:"Storm Damage Cleanup"
  },
  {
    id: 2,
    img: s2,
    alt:"Storm damage restoration"
  },
  {
    id: 3,
    img: s3,
    alt:"Wind damage restoration"
  },
  {
    id: 4,
    img: s4,
    alt:"Fence repair after storm"
  },
  {
    id: 5,
    img: s5,
    alt:"Deck repair after storm"
  },
  {
    id: 6,
    img: s6,
    alt:"topdog"
  },
  {
    id: 7,
    img: s7,
    alt:"Water damage repair after storm"
  },
  {
    id: 8,
    img: s8,
    alt:"Electrical repair after storm"
  },
  {
    id: 9,
    img: s9,
    alt:"Storm damage inspection"
  },
  
  
]

export const  bioDamage=[
  {
    id: 1,
    img: b1,
    alt:"Bio-Hazard Cleanup"
  },
  {
    id: 2,
    img: b2,
    alt:"Bio Hazard Cleanup"
  },
  {
    id: 3,
    img: b3,
    alt:"Biohazard Cleanup"
  },
  {
    id: 4,
    img: b4,
    alt:"Biohazard Cleanup"
  },
  {
    id: 5,
    img: b5,
    alt:"topdog Biohazard Cleanup"
  },
  {
    id: 6,
    img: b6,
    alt:"Top Dog Contractors company" 
  },
  
  
]

export const  moldDamage=[
  {
    id: 1,
    img: m1,
    alt:"Storm Damage Restoration"
  },
  {
    id: 2,
    img: m2,
    alt:"Top Dog Contractors"
  },
  {
    id: 3,
    img: m3,
    alt:"top dog contractors"
  },
  {
    id: 4,
    img: m4,
    alt:"Top Dog Contractors"
  },
  {
    id: 5,
    img: m5,
    alt:"mold"
  },
  {
    id: 6,
    img: m6,
    alt:"fire damage"
  },
  {
    id: 7,
    img: m7,
    alt:"Residential Construction Services"
  },
  {
    id: 8,
    img: m8,
    alt:"Mold Remediation Specialists"
  },
  {
    id: 9,
    img: m9,
    alt:"Restoration After Fire Damage"
  },
  {
    id: 10,
    img: m10,
    alt:"construction"
  },
  {
    id: 11,
    img: m11,
    alt:"topdog company"
  },
  
]
const service = [
  {
    id: 1,
    title: "Water Damage Restoration",
    img: water,
    desc: "Providing 24/7 Emergency Services we are here to support you every step of the way in restoring your property back to normal.​ Timing is critical to reducing further water damage. Our IICRC trained and certified technicians are well qualified to perform a variety of water damage restoration services.",
    link: "/restoration-and-cleaning/water-damage-restoration",
    alt: "water damage restoration",
  },
  {
    id: 2,
    title: "Mold Remediation",
    img: mold,
    desc: "Mold can spread quickly and lead to numerous health problems. If you suspect mold, call us immediately. We will inspect your home, assess the damage, and provide a plan to remove the mold and restore your property.",
    link: "/restoration-and-cleaning/mold-remediation",
    alt: "mold remediation",
  },
  {
    id: 3,
    title: "Fire Damage Restoration",
    img: fire,
    desc: "At Top Dog Contractors, our certified professionals determine the full extent of fire damage, including the impact of smoke, heat, and residues from fire and smoke.",
    link: "/restoration-and-cleaning/fire-damage-restoration",
    alt: "fire damage restoration",
  },
  {
    id: 4,
    title: "Storm Damage Recovery",
    img: storm,
    desc: "We understand how fire damage can affect you emotionally and physically. Our IICRC certified restoration company is trained to minimize replacement costs by cleaning and restoring your property to its pre-fire condition, while also salvaging your personal belongings",
    link: "/restoration-and-cleaning/storm-damage-restoration",
    alt: "storm damage Recovery",
  },
  {
    id: 5,
    title: "Biohazard Cleanup",
    img: biohazard,
    desc: "Top Dog Contractors uses EPA-registered products for deep mitigation cleaning, reducing the risk of viral pathogen spread. Our quick and compassionate remediation services make us the ideal choice for damage restoration.",
    link: "/restoration-and-cleaning/biohazard-cleanup",
    alt: "biohazard cleanup",
  },
  {
    id: 6,
    title: "Air Duct Cleaning",
    img: airduct,
    desc: "Dirty ducts in your home, school or workplace may contribute to larger energy bills, exacerbate health issues or harbor contaminants. Top Dog Contractors can help with our professional air duct cleaning services",
    link: "/restoration-and-cleaning/air-duct-cleaning",
    alt: "air duct cleaning",
  },
   
];


export const remodeling = [
  {
    id: 1,
    title: "Kitchen Remodeling / Renovation",
    img: k1,
    desc: "The kitchen is the centerpiece of the home and, as such, should be a space that’s as welcoming and beautiful as it is functional and thoughtfully designed. If the kitchen in your Greater Triangle Area and Beyond home feels cramped, cluttered, or simply outdated, Top Dog Contractors can help.",
    link: "/remodeling-and-renovation/kitchen-remodeling",
    alt: "kitchen remodeling / renovation",
  },
  {
    id: 2,
    title: "Bathroom Remodeling / Renovation",
    img: bathroom,
    desc: "Your bathroom is one of the most used rooms in your home, yet, for many, it lacks design, functionality, and comfort. With nearly two decades as an industry leader, Top Dog Contractors has extensive experience transforming outdated or poorly designed bathrooms into beautiful, relaxing spaces.",
    link: "/remodeling-and-renovation/bathroom-remodeling",
    alt: "Bathroom Remodeling / Renovation",
  },
  {
    id: 3,
    title: "Whole Home Remodeling / Renovation",
    img: home,
    desc: "Are you interested in modernizing your interior? Perhaps you want your home to better suit your aesthetic tastes, or maybe it no longer fits your needs. Whatever the case, the experienced, knowledgeable team at Top Dog Contractors is here for you.",
    link: "/remodeling-and-renovation/home-remodeling",
    alt: "Whole Home Remodeling / Renovation",
  },
  {
    id: 4,
    title: "Condo Remodeling / Renovation",
    img: condo,
    desc: "Are you a condo owner in Greater Triangle Area and Beyond? Even though your exterior walls are attached to other dwellings, you can still renovate the interior. Whether you prefer traditional design, modern styling, or something in between, the team at Top Dog Contractors can help.",
    link: "/remodeling-and-renovation/condo-remodeling",
    alt: "Condo Remodeling",
  },
  {
    id: 5,
    title: "Basement Remodeling / Renovation",
    img: basement,
    desc: "An unfinished basement is nothing more than wasted square footage. Turn this vast, concrete room into a comfortable, relaxing living space with help from Top Dog Contractors.",
    link: "/remodeling-and-renovation/basement-remodeling",
    alt: "Basement Remodeling",
  },
  {
    id: 6,
    title: "Master Suite Remodeling / Renovation",
    img: bedroom,
    desc: "Do you dream of having a luxurious master suite? Separate from the noise and chaos of the rest of the house, a master suite is a tranquil oasis where you can bathe, dress, and relax in peace. Choose Top Dog Contractors for your master suite renovation in Greater Triangle Area and Beyond.",
    link: "/remodeling-and-renovation/master-suite-remodeling",
    alt: "Master Suite Remodeling ",
  },
   
];


export const fireDamage=[

  {
    id:1,
    img:f1,
    alt:"fire damage"
  },
  {
    id:2,
    img:f2,
    alt:"Fire damage restoration"
  },
  {
    id:3,
    img:f3,
    alt:"Storm damage restoration"
  },
  {
    id:4,
    img:f4,
    alt:"Water damage restoration"
  },
  {
    id:5,
    img:f5,
    alt:"Storm damage restoration"
  },
  {
    id:6,
    img:f6,
    alt:"Mold damage restoration"
  },
  {
    id:7,
    img:f7,
    alt:"Structural restoration"
  },
  {
    id:8,
    img:f8,
    alt:"Emergency restoration"
  },
  {
    id:9,
    img:f9,
    alt:"Top Dog"
  },
  {
    id:10,
    img:f10,
    alt:"topdog"
  },
  

]
export const  damageGallary=[
  {
    id:1,
    img:d1,
    alt:"Top Dog Contractors"
  },
  {
    id:2,
    img:d2,
   alt:"Top Dog"
  },
  {
    id:3,
    img:d3,
    alt:"toptog contractors"
  },
  {
    id:4,
    img:d4,
    alt:"contractors"
  },
  {
    id:5,
    img:d5,
    alt:"restoration company"
  },
  {
    id:6,
    img:d6,
    alt:"remodeling company"
  },
  {
    id:7,
    img:d7,
    alt:"fire damage restoration company"
  },
  {
    id:8,
    img:d8,
    alt:"desaster recovery"
  },
  {
    id:9,
    img:d9,
    alt:"desaster recovery company"
  },
  {
    id:10,
    img:d10,
    alt:"Mold Removal"
  },
  {
    id:11,
    img:d11,
    alt:"Mold Remediation"
  },
  {
    id:12,
    img:d12,
    alt:"Air Duct Cleaning"
  },
]
export default service;



