import React from "react";
import "./sidebar.scss";
import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
  return (
    <div className="side-custom-card">
      <h4 className="mt-2">Top Dog Contractors Services</h4>

      <ul className="sidbar-ul">
         
        <li>
          <NavLink to="/restoration-and-cleaning/water-damage-restoration">
            <BsArrowRight /> Water Damage Restoration
          </NavLink>
        </li>
        <li>
          <NavLink to="/restoration-and-cleaning/fire-damage-restoration">
            <BsArrowRight /> Fire Damage Restoration
          </NavLink>
        </li>
        <li>
          <NavLink to="/restoration-and-cleaning/mold-remediation">
            <BsArrowRight /> Mold Remediation
          </NavLink>
        </li>

        <li>
          <NavLink to="/restoration-and-cleaning/storm-damage-restoration">
            <BsArrowRight /> Storm Damage Restoration
          </NavLink>
        </li>
        <li>
          <NavLink to="/restoration-and-cleaning/biohazard-cleanup">
            <BsArrowRight /> Bio-Hazard Cleanup
          </NavLink>
        </li>
        <li>
          <NavLink to="/restoration-and-cleaning/air-duct-cleaning">
            <BsArrowRight /> Air Duct Cleaning
          </NavLink>
        </li>

        <li>
          <NavLink to="/remodeling-and-renovation/kitchen-remodeling">
            <BsArrowRight /> Kitchen Remodeling/Renovation
          </NavLink>
        </li>
        <li>
          <NavLink to="/remodeling-and-renovation/bathroom-remodeling">
            <BsArrowRight /> Bathroom Remodeling/Renovation
          </NavLink>
        </li>
        <li>
          <NavLink to="/remodeling-and-renovation/home-remodeling">
            <BsArrowRight /> Home Remodeling/Renovation
          </NavLink>
        </li>

        <li>
          <NavLink to="/remodeling-and-renovation/condo-remodeling">
            <BsArrowRight /> Condo Remodeling/Renovation
          </NavLink>
        </li>
        <li>
          <NavLink to="/remodeling-and-renovation/basement-remodeling">
            <BsArrowRight /> Basement Remodeling/Renovation
          </NavLink>
        </li>
        <li>
          <NavLink to="/remodeling-and-renovation/master-suite-remodeling">
            <BsArrowRight /> Master Suit Works
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
