import React, { useEffect, useState } from "react";
import "./commercial.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
 
import Sidebar from "../../../components/sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const Condo = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="need ">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1>GETTING YOU BACK TO BUSINESS IS OUR BUSINESS.</h1>
              <p className="mb-4">
                After a disaster, you need immediate response to minimize
                business interruption and plan for recovery. Make us your first
                call in an emergency.
              </p>
              <a
                href="tel: 919-373-2882
"
              >
                Call 919-373-2882
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1 mt-4 mt-lg-1">
                COMMERCIAL RESTORATION SERVICES
              </h1>

              <p>
                At Top Dog Contractors, we know how important it is to quickly
                get you back to business and minimize business interruption and
                downtime. Our technicians are certified by the industry-leading{" "}
                <a href="https://iicrc.org/">
                  Institute of Inspection Cleaning and Restoration Certification
                  (IICRC)
                </a>{" "}
                and can meet all your specialized commercial restoration needs.
              </p>
              <p>
                Trust Top Dog Contractors with your commercial cleanup and
                restoration needs. We provide a range of 24/7 emergency
                services, from emergency board-up of buildings to full-service
                general contracting and project management, helping you
                eliminate the hassle of hiring a separate company for
                reconstruction services. Review our commercial restoration and
                emergency services and let us help your business recover.
              </p>

             
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              {/* <Sidebar /> */}
              <div className="side-feature mt-3">
                <h4>24/7 Restoration Services for Commercial Property</h4>
                <ul>
                  <li>
                    <p>
                      Large Loss and Catastrophic Disaster Recovery Services
                    </p>
                  </li>

                  <li>
                    <p>Water Damage Restoration</p>
                  </li>

                  <li>
                    <p>Fire Damage Restoration</p>
                  </li>

                  <li>
                    <p>Mold Removal & Remediation</p>
                  </li>

                  <li>
                    <p>Abatement & Environmental Services</p>
                  </li>

                  <li>
                    <p>Full-Service Reconstruction</p>
                  </li>

                  <li>
                    <p>Emergency Board-Up & Roof Tarping</p>
                  </li>

                  <li>
                    <p>Contents Moving, Inventory and Storage</p>
                  </li>

                  <li>
                    <p>Walk-in tubs or built-in shower seats</p>
                  </li>
                  <li>
                    <NavLink to="/restoration-and-cleaning/water-damage-restoration">
                      Commercial Water Damage Restoration
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/restoration-and-cleaning/fire-damage-restoration">
                      Commercial Fire Damage Restoration
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/restoration-and-cleaning/mold-remediation">
                      Commercial Mold Removal & Remediation
                    </NavLink>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content  pt-3 " id="graffity">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6  mt-3  ">
              <div className="  ">
                <h1 className="com-heading">
                  Large Loss and Catastrophic Disaster Recovery Services
                </h1>
                <p>
                  Large-scale commercial restoration projects require expert
                  knowledge, an experienced workforce and specialized equipment
                  to successfully carry out restoration work with minimal
                  business interruption and loss of productivity. Large Loss and
                  major loss commercial restoration projects should be handled
                  by an experienced fire and water damage restoration company.
                </p>

                <p>
                  In the case of major water damage events such as floods and
                  storms, it’s important to properly evaluate the extent of
                  water damage to commercial buildings, materials, and contents.
                  Wet items should be dried expeditiously to prevent indoor mold
                  growth and secondary damages.
                </p>

                <p>
                  Flood water can be contaminated by sewage, chemicals, and
                  bacteria. Contaminated water damage must be handled
                  responsibly by remediation experts. Water intrusion, leaks,
                  and other sources of water damage are a reality in many types
                  of commercial buildings. Water infiltration can lead to mold
                  growth and bacterial contamination in cases of unsanitary
                  water sources. This can result is health and safety risks to
                  occupants, tenants, and employees.
                </p>

                <p>
                  Similarly, commercial fire and smoke damage restoration
                  projects must be handled by an experienced restoration
                  company. After fire damage has been discovered, our team will
                  quickly rescue any contents and inventory that have been
                  damaged by fire and smoke.
                </p>
              </div>
            </div>

            <div className=" col-lg-6  mt-3  ">
              <p>
                <b>We Service</b>
              </p>
              <ul className="list-unstyled commercial-list">
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Flood and Water Damage
                  Restoration
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Fire and Smoke Damage
                  Restoration
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Mould Removal and
                  Remediation
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Asbestos Abatement
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Large Loss and Major Loss
                  Project Management
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Catastrophic Storm Response
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Document and Media Recovery
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Electronics and Equipment
                  Restoration
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Contents Restoration,
                  Handling, Inventory and Storage
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Temporary Business
                  Relocation Services
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Vandalism and Vehicle Impact
                  Damage
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Soda/Dry Ice Blasting
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Wind and Lightning Damage
                </li>

                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Emergency Board-Up and Roof
                  Tarping
                </li>

                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Temporary Power and Climate
                  Control Solutions
                </li>

                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Demolition and Environmental
                  Services
                </li>

                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Deodorization
                </li>

                <li>
                  {" "}
                  <BsArrowRight className="icon" /> HVAC Cleaning
                </li>
                <li>
                  {" "}
                  <BsArrowRight className="icon" /> Full-Service General
                  Contracting and Building Renovation
                </li>
              </ul>

              <NavLink to="/contact" className="text-decoration-none">
                Get a free consultation today!
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="water-restor py-3 py-lg-5 com-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center ">
              <img
                src={require("../../../asset/img/water-fire.png")}
                alt="water damage"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3 mt-md-0">
              <h2 className="com-heading">Water Damage Restoration</h2>
              <p>
                Damage to commercial property due to flood, or other types of
                water damage, can result in an interruption of your business,
                loss of revenue, and potential loss of customers. If your
                business requires water damage restoration due to broken
                plumbing, flood damage, or because of an activated fire
                suppression system - timing is of the essence. As IICRC
                certified water damage restoration experts, Top Dog Contractors
                uses the most advanced tools and equipment to minimize the
                extent of damage to your business. Our number 1 priority is
                getting you back to business as quickly as possible.
              </p>

              <h2 className="com-heading">Fire Damage Restoration</h2>
              <p>
                If your business has been affected by fire or smoke damage, you
                need professional assistance from an experienced commercial
                property damage restoration company. Our IICRC certified
                technicians are trained in fire and smoke damage restoration,
                soot cleaning, deodorization, contents cleaning, and corrosion
                control. As a full-service disaster recovery company, we provide
                reconstruction and building remodeling/renovation services. We
                are here to support you throughout the entire restoration
                project.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="abatement-environmental-services">
        <div className="container">
          <div className="row py-5 py-lg-0">
            <div className="col-lg-7 order-last order-lg-first py-lg-5">
              <h1 className="com-heading pt-4 pt-lg-0">
                Mold Removal & Remediation
              </h1>
              <p>
                Professional mold remediation protects the health of your
                customers and employees, preserves the structural integrity of
                your property, and removes the awful smell that comes with mold.
                Following water damage or flooding, your business is at serious
                risk of developing mold. Mold can grow within 48 hours on wet or
                damp surfaces such as carpet, drywall, wood, ceiling tiles, and
                underneath flooring. By eliminating the source of water
                intrusion and beginning the water damage restoration process as
                quickly as possible, we can prevent mold growth.
              </p>

              <h1 className="com-heading pt-4 pt-lg-0">
                Abatement & Environmental Services
              </h1>
              <p>
                We specialize in safely removing all types of
                asbestos-containing materials including drywall, vinyl tile,
                textured ceiling, pipe insulation, vermiculite, and more. We
                contain the work area and utilize HEPA negative air machines to
                prevent hazardous airborne particulates from migrating
                throughout your commercial property or work environment. We are
                experts in removing hazardous materials and providing the
                environmental remediation services required, after fire damage
                or water damage.
              </p>

              <NavLink to="/contact" className="text-decoration-none">
                Get a free consultation today!
              </NavLink>
            </div>
            <div className="col-lg-5">
              <div className="janitorial"></div>
            </div>
          </div>
        </div>
      </div>

     
      <div id="floor-care " className="com-bg">
        <div className="container">
          <div className="row py-3 py-lg-5">
            <div className="col-lg-6">
              <img src={require("../../../asset/img/services/commercial/roofing.png")} alt="topdog remodeling" className="img-fluid" />
            </div>
            <div className="col-lg-6  mt-3 mt-md-0">
              <h1 className="com-heading pt-4 pt-lg-0">
              Full-Service Reconstruction
              </h1>
              <p>
              When your business has experienced the unthinkable, you need a full-service disaster recovery company you can trust. We are experts at restoring and rebuilding commercial properties damaged by water, fire, and/or mold. In some cases, property damage can be so extensive that reconstruction services are necessary. As a full-service disaster recovery company, we manage the entire reconstruction process from beginning to end, ensuring the project is completed efficiently with minimal business interruption.
              </p>

              <h1 className="com-heading pt-4 pt-lg-0">
              Emergency Board-Up & Roof Tarping
              </h1>
              <p>
              If your business suffers commercial property damage, the cleanup and restoration team at Top Dog Contractors are contents restoration, inventory, and pack-out experts. On commercial restoration projects, our top priority is to minimize business interruption while starting the inventory recovery process as soon as possible. Many restoration projects have contents and furniture that need to be inventoried, packed, and safely transported to our climate-controlled facilities for cleaning, deodorization, and storage. When the reconstruction process is complete, all items will be safely delivered. 
              </p>

              <NavLink to="/contact" className="text-decoration-none">
                Get a free consultation today!
              </NavLink>
            </div>
          </div>
        </div>
      </div>

     

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span> Call for </span> Emergency Cleanup and Restoration
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>Whether you are a small business owner or a large corporation, our goal at Top Dog Contractors is to help you overcome the impact caused by commercial property damage. We believe open lines of communication and efficiently managing the process, is key to a successful restoration project. </p>
              <p>Top Dog Contractors understands the urgency to quickly respond to commercial property damage. Our team of IICRC certified personnel provide industry-leading response times and comprehensive assessments, supported by the use of state-of-the-art restoration equipment.

</p>
              <p>Whether you are a small business owner or a large corporation, our goal at Top Dog Contractors is to help you overcome the impact caused by commercial property damage. We believe open lines of communication and efficiently managing the process, is key to a successful restoration project.
              </p>

             
              <h2>
                <b>
                  Schedule your consultation with Top Dog Contractors, call
                  <a href="tel:(919) 373-2882" className="phone">
                    {" "}
                    (919) 373-2882{" "}
                  </a>
                  to get started.
                </b>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Commercial Work Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/condo/co2.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Commercial Work</h2>
                  <p>
                    Topdog commercial remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/condo/co1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/condo/co3.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k6.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/condo/co4.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Commercial Work</h2>
                  <p>
                    Topdog commercial remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/condo/co5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in6.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in7.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b12.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b13.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Condo;
