import React, { useEffect, useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bannerImg from "../../../asset/img/services/basement-banner.jpg";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Basement = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

  const heading = "Basement Remodeling & Renovation";
  const content =
    "An unfinished basement is nothing more than wasted square footage. Turn this vast, concrete room into a comfortable, relaxing living space with help from Top Dog Contractors";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
                Basement Remodeling in Greater Triangle Area, North Carolina
              </h1>

              <p>
                An unfinished basement is nothing more than wasted square
                footage. Turn this vast, concrete room into a comfortable,
                relaxing living space with help from Top Dog Contractors
              </p>
              <p>
                We offer a complete renovation experience from start to finish,
                with a commitment to outstanding quality and on-time and
                on-budget completion. Our team is dedicated to going above and
                beyond and to guarantee your satisfaction.
              </p>
              <h2>
                <b>
                  For Basement Renovations or Remodeling you can trust in
                  Greater Triangle Area, call{" "}
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>{" "}
                  to get started.
                </b>
              </h2>
              <div className="side-feature mt-3">
                <h6>
                  Basement Designing in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Game rooms</li>
                  <li>Game rooms</li>
                  <li>Home theaters</li>
                  <li>Family rooms</li>
                  <li>Wine cellars</li>
                  <li>Craft rooms</li>
                  <li>Home gyms</li>
                  <li>Apartment suites</li>
                  <li>In-law suites</li>
                  <li>Project management</li>
                  <li>Construction</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>REASONS TO RENOVATE YOUR BASEMENT</h5>
                <p>
                  If you have a basement in your home that is not finished, you
                  have wasted space. A basement renovation allows you to utilize
                  that space and transform it into something you can enjoy.
                  There are many reasons to invest in a basement renovation,
                  including:
                </p>
                <ul>
                  <li>The desire for a rec room or family room</li>
                  <li>The need for another bedroom</li>
                  <li>The desire for an entertainment area</li>
                  <li>The desire to create a fun playroom</li>
                  <li>The need for an indoor workout space</li>
                </ul>

                <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call{" "}
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a>{" "}
                    to get started.
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/basement/basement-mockup.webp")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/basement/basement.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3   ">
              <h1 className="process-heading">
                <span>Our Basement</span>  Renovation Process
              </h1>
              <p>
                Top Dog Contractors proudly takes on every aspect of your
                basement renovation project. This simplifies the process because
                you don’t need to hire multiple companies to get the job done
              </p>
              <ul>
                <li className="mb-3">
                  <b>Understand client expectations:</b> Our first goal is to
                  learn your wants, needs, and goals for finishing your
                  basement. If our services seem like the right fit, we’ll move
                  on to the next step.
                </li>
                <li className="mb-3">
                  <b>Design and planning:</b> We’ll work with you to design a
                  basement that meets your needs and budget. We’ll also help you
                  choose the right materials and finishes for your project.
                </li>
                <li className="mb-3">
                  <b>Construction:</b> Once the design is finalized, we’ll get
                  to work on the construction phase of your basement renovation.
                  We’ll keep you updated on our progress and let you know if any
                  issues arise.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
                Whether your renovation involves one room, basement or the entire house,
                you want a team that boasts keen attention to detail and
                preparedness for any surprises that could arise. Approach us
                with nothing more than a problem that needs solving, and we’ll
                get to work designing and delivering a solution.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                <b>
                  Schedule your consultation with Top Dog Contractors, call
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>
                  to get started.
                </b>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Basement Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/basement/bs3.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Basement Remodeling Gallery</h2>
                  <p>
                    Topdog Basement remodeling and renovation gallery in the region
                    of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/basement/bs1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs5.webp")}`,
                    },
                   
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/basement/bs7.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Basement Remodeling Gallery</h2>
                  <p>
                    Topdog Basement remodeling and renovation gallery in the region
                    of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/basement/bs8.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs9.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs10.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/basement/bs1.webp")}`,
                    },
                   
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Basement;
