import React, { useEffect, useState } from "react";
import "./bathroom.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bannerImg from "../../../asset/img/services/bath-banner.jpg";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Bathroom = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

  const heading = "Bathroom Remodeling & Renovation";
  const content =
    "Your bathroom is one of the most used rooms in your home, yet, for many, it lacks design, functionality, and comfort​";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-first order-lg-last">
              <h1 className="water-heading-1">
                Custom Bathroom Remodeling & Renovation
              </h1>

              <p>
                Your bathroom is one of the most used rooms in your home, yet,
                for many, it lacks design, functionality, and comfort. With
                nearly two decades as an industry leader, Top Dog Contractors has
                extensive experience transforming outdated or poorly designed
                bathrooms into beautiful, relaxing spaces.
              </p>
              <p>
                We perform exceptional work with superior products to turn your
                vision into a reality.
              </p>
              <h2>
                <b>
                  For bathroom renovations you can trust in Greater Triangle
                  Area, call{" "}
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>{" "}
                  to get started.
                </b>
              </h2>
              <div className="side-feature mt-3">
                <h6>
                  Bathroom Designing in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Double vanities</li>
                  <li>Walk-in showers</li>
                  <li>Soaking tubs</li>
                  <li>High-efficiency toilets</li>
                  <li>Rainfall showerheads or shower jets</li>
                  <li>Walk-in tubs or built-in shower seats</li>
                  <li>Built-in storage</li>
                  <li>Radiant floor heating</li>
                  <li>LED lighting</li>
                  <li>Large tiles</li>
                  <li>Custom shampoo niche</li>
                  <li>Steamers</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>
              
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>Why Renovate Your Bathroom?</h5>
                <p>
                  There are several reasons you might want to renovate your
                  bathroom. For many, it’s a matter of modernizing an outdated
                  design. For others, it’s creating a home spa for a relaxing
                  retreat from daily life. Still, others are making adjustments
                  to age in place.
                </p>
                <p>
                  Whatever the reason, bathroom renovations typically generate,
                  on average, a 72% return on investment. That means you can
                  boost your home’s value while also increasing your quality of
                  life.
                </p>

                <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call{" "}
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a>{" "}
                    to get started.
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/bathroom/bathroom-mockup.webp")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/bathroom/bathroom.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3 p-2 px-4 ">
              <h1 className="process-heading ">
                <span>Process</span>
              </h1>

              <ul className="process-ul">
                <li>
                  <b>Client expectations:</b> We’ll start by discussing your
                  wants, needs, and goals for your bathroom renovation.
                </li>

                <li>
                  <b>Design and preconstruction:</b> Our in-house architects and
                  designers will develop drawings, 3D renderings, concept art,
                  and a list of materials and finishes for you to review.
                </li>

                <li>
                  <b>Contract and construction: </b> Once you’ve signed off on a
                  final design, our team will transform your plans from paper to
                  reality.
                </li>

                <li>
                  <b>Final walk-through:</b> We don’t consider the job complete
                  until we do a final walk-through to ensure our work meets your
                  standards and expectations.
                </li>
              </ul>

              <p>
                We strive to provide an unparalleled renovation experience at
                every step of the process. Our goal is not only a beautiful
                bathroom but also your peace of mind, from start to finish.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
                When you trust our expert designers, architects, and builders
                for your bathroom renovation, you can expect unmatched
                experience, craftsmanship, and professionalism.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call 
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a> 
                    to get started.
                  </b>
                </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Bathroom Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/bathroom/b1.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Bathroom Remodeling Gallery</h2>
                  <p>
                    Topdog Bathroom remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/bathroom/b1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b3.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b6.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/bathroom/b7.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Bathroom Remodeling Gallery</h2>
                  <p>
                    Topdog Bathroom remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/bathroom/b8.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b9.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b10.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b11.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b12.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b13.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bathroom;
