import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import bannerImg from "../../../asset/img/services/fire-dummy.webp";
import "./fire.scss";
import { fireDamage } from "../../../utils/service";
import ReBanner from "../../../components/service-banner/ReBanner";
import Sidebar from "../../../components/sidebar/Sidebar";
const Fire = () => {
  const heading = "EXPERIENCE MATTERS WHEN YOU HAVE FIRE DAMAGE.";
  const content =
    "We understand that dealing with fire and smoke damage can be very emotional. We help you overcome your loss by explaining the cleaning and restoration process.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />{" "}
      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">FIRE DAMAGE RESTORATION</h1>

              <p>
                We recognize the emotional and physical impacts fire damage has
                on property owners, and we work with determination and
                compassion to mitigate secondary damages and salvage your
                personal property. As an{" "}
                <a href="https://iicrc.org/">IICRC trained and certified</a>{" "}
                smoke and fire damage restoration company, we can often minimize
                replacement costs by cleaning and restoring your property to
                pre-fire condition.
              </p>

              <h4>What is Smoke and Fire Damage Restoration? </h4>
              <p>
                Fire and smoke damage restoration is a process that begins as
                soon as a professional engineer or building inspector says the
                damaged property is safe to enter. Believe it or not, in some
                cases the steps taken by the fire department to extinguish the
                fire can cause extensive{" "}
                <Link to="/services/water-damage-restoration">
                  water damage
                </Link>{" "}
                to buildings and personal property.
              </p>
              <p>
                While fighting the fire, the fire department may have broken
                windows and cut holes in the roof. This slows down the growth of
                flames and releases dark smoke that can make it difficult for
                firefighters to see. The firefighters may also cut holes in
                walls to make sure that the fire is completely extinguished and
                there is no risk of any smoldering "hot spots" hidden in the
                walls.
              </p>

              <p>
                Our priority is to help you return to your home or business as
                quickly as possible causing minimum disruption to you and your
                family. Ready to help, any time of day or night - our team is
                highly trained, experienced and ready to assist you in any fire
                or smoke damage situation imaginable. Providing{" "}
                <b>24/7 Emergency Services</b> , we are always here to support
                you every step of the way in cleaning and restoring your fire
                damaged property back to normal.​ Our restoration technicians
                are highly trained and well qualified to perform a variety of
                fire and smoke damage restoration services. We handle all
                furniture and personal items with extra care and take the
                necessary steps to protect belongings as if they were our own.
                Our emergency fire damage repair and reconstruction services can
                restore your fire and smoke damaged property back to the
                original condition as quickly as possible, helping make the
                experience less stressful.
              </p>

              <div className="cost">
                <p>A prompt response using the</p>
                <h1>Top Dog Contractors can save 15-40%</h1>
                <p>on fire damage claim costs.</p>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <div className="side-feature">
                    <h6>Fire Damage Restoration Services​ ​</h6>
                    <ul>
                      <li>Emergency Fire Damage Cleanup and Repair</li>

                      <li>Wall and Ceiling Cleaning</li>
                      <li>Fire and Smoke Odour Removal</li>
                      <li>Fire and Smoke Odour Removal</li>
                      <li>Soda/Dry Ice Blasting and Smoke Seal</li>
                      <li>Contents Total Loss Reports</li>
                      <li>Temporary Board-Up and Security Solutions</li>
                      <li>Temporary Power and Climate Control Solutions</li>
                      <li>Temporary Business Relocation Services</li>
                      <li>
                        Major Loss and Commercial Water Damage Restoration
                      </li>
                      <li>Demolition and Environmental Services</li>
                      <li>And Many More</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 mt-3 order-first order-lg-last">
                  <div className="side-feature">
                    <h6>The Fire Damage Restoration Process ​ ​</h6>
                    <ul>
                      <li>Emergency Cleanup and Debris Removal</li>
                      <li>Temporary Security/Board Up</li>
                      <li>Water Removal and Drying</li>
                      <li>Itemization of Damaged Contents</li>
                      <li>Contents Pack-Out</li>
                      <li>Contents Cleaning (off-site)</li>
                      <li>Wall and Ceiling Cleaning</li>
                      <li>Deodorization</li>
                      <li>Soda/Dry Ice Blasting</li>
                      <li>Smoke Seal</li>
                      <li>Reconstruction</li>
                      <li>Contents Delivery, Unpack and Reset</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <Sidebar />
            </div>

            <div className="col-md-12 mt-3">
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose">
        <div className="container">
          <h1>Why Choose Top Dog Contractors</h1>
          <p>
            {" "}
            Top Dog Contractors answers your call and immediately moves into
            action 24/7. After a fire is extinguished, timing is critical. Fire
            damage repairs must begin quickly to maximize effectiveness.
          </p>
          <div className="row pt-3">
            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Quick and Responsive</h4>
                <p>
                  After a fire is extinguished, damage continues for days; quick
                  response can be the difference between recovery and permanent
                  damage.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Ongoing Communication</h4>
                <p>
                  At every step, we keep you and your insurance agent informed.
                  We want everyone involved to understand what is happening,
                  what to expect, and what to do to overcome the loss.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/van-icon.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>Fully Trained and Certified</h4>
                <p>
                  As a leader in the restoration industry with over 20 years of
                  disaster recovery experience, PuroClean is exceptionally
                  qualified to remediate property damage.
                </p>
              </div>
            </div>

            <div className="col-md-6 my-4 px-md-4 mt-md-5 d-flex">
              <div className="icon">
                <img
                  src={require("../../../asset/img/cogs.webp")}
                  alt="fire damage restoration"
                />
              </div>
              <div className="content">
                <h4>State-of-the-art Equipment</h4>
                <p>
                  Our state-of-the-art techniques and cleaning solutions can
                  dramatically increase the likelihood of saving property
                  without needing to replace it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process">
        <div className="container">
          <h1>The Fire Damage Restoration Process</h1>
          <div className="row">
            <div className="col-md-6 mt-3">
              <h4>1. Assessing the degree of damage</h4>
              <p>
                Firstly, the restoration technicians inspect the property and
                assess the extent of the fire, smoke, and soot damage. They also
                assess the damage from the water that was used to put out the
                fire. Doing so allows them to discover all the visible and
                hidden damage.
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>2. Handling of contents</h4>
              <p>
                After the extent of the damage is determined, the technicians
                will move or remove items from the property. Items can be either
                restored or discarded, depending on the amount of damage they
                suffered. Salvageable items can be restored on-site or at a
                different location.
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>3. Protecting the property</h4>
              <p>
                A fire most likely damages the roofs, walls, and windows. This
                can compromise the security of the property. The restoration
                team will provide board-up and roof-trap services to the
                property to secure it from intruders.
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>4. Water removal and drying</h4>
              <p>
                Once the property is secure, the damage restoration continues
                with removing the excess water that resulted from extinguishing
                the fire. Then, the technicians will dry the property to prevent
                further{" "}
                <Link to="/services/water-damage-restoration">
                  water damage
                </Link>
                .
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>5. Removing smoke and soot residue</h4>
              <p>
                Once the property is dry, we will clean the soot from surfaces,
                such as ceilings, floors, walls, and items using specialized
                equipment. The restoration team will also eliminate biological
                and chemical pollutants. Removing smoke odors is achieved with
                equipment such as foggers, ozone generators, and hydroxyl
                generators.
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>6. Cleaning and sanitization</h4>
              <p>
                At this stage in the fire damage restoration process, the
                technicians remove the remaining dust, debris, and smoke and
                soot residue from items and surfaces, and the property will be
                sanitized.
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h4>7. Property reconstruction*</h4>
              <p>
                In the final step, the restoration team will work to get your
                property back to its pre-loss condition. If needed, the property
                will undergo repairs and reconstruction, such as painting,
                replacing drywall or carpet, remodeling, or other necessary
                modifications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-background white py-5">
        <div className="container">
          <h1 className="mb-4">FIRE DAMAGE PHOTO GALLERY</h1>
          <div className="row">
            {fireDamage.map((item, index) => (
              <div
                className="col-6 col-sm-4-col-md-3 col-lg-3 mt-2"
                key={index}
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="img-fluid map-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Fire;
