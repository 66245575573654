import CountUp from "react-countup";
import { useEffect, useState } from "react";
import "./landing.scss";
import { useInView } from "react-intersection-observer";
import { FaPencilRuler } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { HiUserGroup } from "react-icons/hi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import bannerImg from "../../asset/img/banner-img.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import water from "../../asset/img/water.svg";
import mold from "../../asset/img/virus.svg";
import fire from "../../asset/img/fire.svg";
import storm from "../../asset/img/storm-weather.svg";
import cleaning from "../../asset/img/cleaning.svg";
import kitchen from "../../asset/img/kitchen.svg";
import phone from "../../asset/img/phone.svg";
import bio from "../../asset/img/biohazard.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import hand from "../../asset/img/hand.svg";
import man from "../../asset/img/man.svg";
import bathroom from "../../asset/img/bathroom.png";
import demand from "../../asset/img/demand.svg";
import home from "../../asset/img/home.png";
import bedroom from "../../asset/img/bedroom.png";
import cert from "../../asset/img/cert.svg";
import Basement from "../../asset/img/basement.png";
import { styled } from "styled-components";
import condo from "../../asset/img/condo.png";
import axios from "axios";

const Landing = () => {
  const navigate = useNavigate();
  const [dog, setDog] = useState();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  console.log("random dog", dog);

  const getDogs = async () => {
    console.log("get dogs", dog);
    try {
      const { data } = await axios.get(
        `https://shelter.up.railway.app/api/get-rand-pet`
      );

      if (data.success) {
        console.log(data.dogImg);

        setDog(data.dogImg);
        console.log("dog", dog);
      } else {
        console.log(data.message);
        setDog("../../asset/img/banner-img.webp");
      }
    } catch (error) {
      console.log(error);
      console.log("something went wrong");
      setDog(bannerImg);
    }
  };

  const handleRedirect = () => {
    navigate("/shelter", { state: { dog } });
  };

  console.log("dog", dog);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDogs();
  }, []);

  return (
    <>
      <div className="banner position-relative banner-background">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-6 order-last order-lg-first   about-btn p-0 pt-lg-5 ">
              <div className="banner-content  ">
                <div className="call-container">
                  <div className="animation">
                    <div className="emg-call">
                      <a href="tel:919-373-2882">
                        <img src={phone} alt="" />
                      </a>
                    </div>
                  </div>

                  <h4 className="emergency">24/7 Emergency Service</h4>
                </div>

                <h1 className="banner-heading">
                  Professional <span> Restoration, </span>
                  <span> Remodeling, </span> & More
                </h1>

                <p className="banner-para mt-lg-4">
                  Full Service, residential & commercial, emergency{" "}
                  <span>cleanup</span> and <span>Restoration</span> for
                  disasters of all sizes. Serving the Greater Triangle Area and
                  Beyond.
                  <br /> <br />
                  Your local experts, highly trained and certified in
                  <span> Restoration</span>, <span>Remodeling</span>,
                  <span> Renovation</span> & more. <span>Reconstruction </span>
                  you can’t live without.
                </p>
                {/* <p className="emergency">
                  <a href="tel:919-373-2882">
                     
                    <FiPhoneCall className="phone" /> 24/7 Emergency Restoration
                  </a>
                </p> */}

                <ul className="cert-ul cert-banner">
                  <li>
                    <img
                      src={require("../../asset/img/certifications/1.webp")}
                      alt="Top Dog Contractors"
                      className="img-fluid"
                    />
                  </li>

                  <li>
                    <img
                      src={require("../../asset/img/certifications/3.png")}
                      alt="Top Dog Contractors"
                      className="img-fluid"
                      style={{ maxWidth: "140px" }}
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../asset/img/certifications/remodel_certified.webp")}
                      alt="Top Dog Contractors"
                      className="img-fluid"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6  order-first order-lg-last   mb-4 d-flex  align-items-center  justify-content-center justify-content-lg-start  p-0">
              <div className="position-relative flex-column align-items-center d-flex flex-wrap">
                <img
                  src={bannerImg}
                  alt="top dog contractors "
                  className="main-image"
                />

                <div className="banner-bottom" >
                  <p className="banner-btm-para">
                    Save a life! Adopt your next furry friend from a local
                    shelter
                  </p>
                </div>
                <div className="banner-dog-image bg-black">
                  <img
                    src={dog && dog[0]?.image}
                    alt="shelter"
                    className="img-fluid cursor-pointer "
                    onClick={handleRedirect}
                  />

                  <p className="p-2  white  ">
                    {dog && dog[0]?.name} needs a home.
                    <br /> <b>Adopt today!</b>{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* <Banner className="col-lg-6  order-first order-lg-last p-0 cursor-pointer"  onClick={handleRedirect}  >
              <Link to="/shelter">                
                <div className="ooverlay">                 
                  <h4 className="fw-bold">Top Dogs are in need of a home.  Adopt or Foster to save our furry friends!</h4>
                  <p>Click here to explore more</p>
                </div>
              </Link>
            </Banner> */}
          </div>
        </div>
      </div>

      <div className="counter" ref={ref}>
        {inView && (
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-2 mx-auto">
                <div className="count-item">
                  <div className="icon-container">
                    <FaPencilRuler className="icon" />
                  </div>

                  <CountUp className="number" end={23} duration={3} />
                  <h5 className="text-center">Years of Experience</h5>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mx-auto ">
                <div className="count-item">
                  <div className="icon-container">
                    <BiBuildingHouse className="icon" />
                  </div>

                  <CountUp className="number" end={363} duration={3} />
                  <h5 className="text-center">Completed Projects</h5>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mx-auto">
                <div className="count-item">
                  <div className="icon-container">
                    <HiUserGroup className="icon" />
                  </div>

                  <CountUp className="number" end={22} duration={3} />
                  <h5 className="text-center">Trained Professionals</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="about" id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <img
                src={require("../../asset/img/about.jpg")}
                alt="about Top Dog Contractors"
                className="img-fluid"
              />
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0 d-flex flex-column justify-content-center">
              <div className="about-header">
                <h1>who we are</h1>
                <p>Welcome to Top Dog Contractors</p>
              </div>

              <p className="about-para">
                At Top Dog Contractors, we are dedicated to providing superior
                damage restoration services for our customers. Our team of
                certified professionals has years of experience in restoring
                homes and businesses to their pre-damaged state after incidents
                such as fires, floods, and storms. We pride ourselves on our
                cutting-edge technology and state-of-the-art equipment, which
                allows us to quickly and effectively mitigate damage and prevent
                further destruction.
              </p>
              <p className="about-para">
                We are a fully licensed and insured company that takes pride in
                providing top-quality service that exceeds our customers'
                expectations. At Top Dog Contractors, we're not just restoring
                your property, we're restoring your peace of mind.
              </p>

              <div className="banner-btn about-btn">
                <NavLink to="/contact">Contact us</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="service-cont">
      <div className="container">
          <h1 className="service-heading"><span>Our</span> <br /> Services</h1>         
        </div>
      </div> */}

      <div className="remodeling-services">
        <div className="shape">
          <img
            src={require("../../asset/img/shape.png")}
            alt="cleaning and restoration"
            className="img-fluid"
          />
        </div>

        <div className="container-fluid g-0">
          <div className="row g-0">
            <div className="col-lg-6 p-3 py-4">
              <h1 className="heading-heading">
                Restoration <span>and</span> Cleaning
              </h1>

              <p className="white">
                With years of experience and a passion for customer
                satisfaction, we specialize in providing comprehensive cleaning
                and restoration services for both residential and commercial
                properties. Whether you're dealing with a post-construction
                mess, water damage, fire damage, or simply in need of a thorough
                deep cleaning, we've got you covered
              </p>

              <div className="d-md-flex  flex-wrap">
                <Link
                  to="/restoration-and-cleaning/water-damage-restoration"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={water} className="service-icon" alt="Icon" />
                    <h4>Water Damage Restoration</h4>
                  </div>
                </Link>

                <Link
                  to="/restoration-and-cleaning/mold-remediation"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={mold} className="service-icon" alt="Icon" />
                    <h4>Mold Remediation</h4>
                  </div>
                </Link>

                <Link
                  to="/restoration-and-cleaning/fire-damage-restoration"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={fire} className="service-icon" alt="Icon" />
                    <h4>Fire Restoration Service</h4>
                  </div>
                </Link>

                <Link
                  to="/restoration-and-cleaning/biohazard-cleanup"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={bio} className="service-icon" alt="Icon" />
                    <h4> Bio-Hazard Cleanup</h4>
                  </div>
                </Link>

                <Link
                  to="/restoration-and-cleaning/air-duct-cleaning"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={cleaning} className="service-icon" alt="Icon" />
                    <h4>Air Duct Cleaning</h4>
                  </div>
                </Link>

                <Link
                  to="/restoration-and-cleaning/storm-damage-restoration"
                  className="text-decoration-none mt-3 me-2"
                >
                  <div className="hover-card">
                    <img src={storm} className="service-icon" alt="Icon" />
                    <h4>Storm Damage Restoration</h4>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cleaning-bg"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="blueprint">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h4>We provide a lot more</h4>
              <h1 className="commercial">
                Our
                <br />
                <span>Commercial Services</span>{" "}
              </h1>
              <p className="about-para">
                At Top Dog Contractors, we know how important it is to quickly
                and efficiently get you back to business and we aim to minimize
                business interruption and downtime. Our technicians are
                certified by the industry-leading Institute of Inspection
                Cleaning and Restoration Certification (IICRC) and can meet all
                of your specialized commercial restoration needs.
              </p>
              <div className="mt-3">
                <NavLink to="/commercial-services" className="commercial-link">
                  View Details
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <img
                src={require("../../asset/img/commercial-lan.jpeg")}
                alt="commercial services"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="remodeling-services">
        <div className="shape">
          <img
            src={require("../../asset/img/shape.png")}
            alt="cleaning and restoration"
            className="img-fluid"
          />
        </div>

        <div className="container-fluid g-0">
          <div className="row g-0   ">
            <div className="col-lg-6 order-last order-lg-first">
              <div className="cleaning-bg remodeling-bg"></div>
            </div>
            <div className="col-lg-6 p-3 py-4">
              <h1 className="heading-heading">
                Remodeling <span>and</span> Renovation
              </h1>

              <p className="white">
                Whether you're looking to transform your kitchen, bathroom,
                basement, or any other part of your home or business, we have
                the expertise and experience to bring your vision to life. Our
                team of skilled craftsmen and designers are dedicated to
                delivering exceptional results, creating spaces that are both
                functional and aesthetically pleasing.
              </p>

              <div className="d-md-flex  flex-wrap">
                <Link
                  to="/remodeling-and-renovation/kitchen-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={kitchen} className="service-icon" alt="Icon" />
                    <h4>Kitchen Remodel / Renovate</h4>
                  </div>
                </Link>

                <Link
                  to="/remodeling-and-renovation/bathroom-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={bathroom} className="service-icon" alt="Icon" />
                    <h4>Bathroom Services</h4>
                  </div>
                </Link>

                <Link
                  to="/remodeling-and-renovation/home-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={home} className="service-icon" alt="Icon" />
                    <h4>Home Remodel / Renovate</h4>
                  </div>
                </Link>

                <Link
                  to="/remodeling-and-renovation/condo-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={condo} className="service-icon" alt="Icon" />
                    <h4>Condo Remodel / Renovate</h4>
                  </div>
                </Link>

                <Link
                  to="/remodeling-and-renovation/basement-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={Basement} className="service-icon" alt="Icon" />
                    <h4>Basement Services</h4>
                  </div>
                </Link>

                <Link
                  to="/remodeling-and-renovation/master-suite-remodeling"
                  className="text-decoration-none mt-3 me-3"
                >
                  <div className="hover-card">
                    <img src={bedroom} className="service-icon" alt="Icon" />
                    <h4>Master Suit Services</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionVission">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-lg-4  d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <img
                    src={
                      require("../../asset/img/engineer-svgrepo-com.svg")
                        .default
                    }
                    alt="not_found"
                  />
                </div>

                <h6 className="title">
                  <b>Quality Construction</b>
                </h6>

                <p className="description">
                  Quality in construction can be defined as the attainment of
                  acceptable levels of performance from construction activities.
                  <br /> We build to surpass what's acceptable!
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon-box icon-box-cyan">
                <div className="icon">
                  <img
                    src={
                      require("../../asset/img/engineer-worker-svgrepo-com.svg")
                        .default
                    }
                    alt="mot_found"
                  />
                </div>

                <h6 className="title">
                  <b>Professional Liability</b>
                </h6>

                <p className="description">
                  Professional liability and insurance policies cover any
                  damages that arise from the rendering of contruction services.
                  <br /> We guarantee all services provided.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 mx-auto d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <img
                    src={
                      require("../../asset/img/engineer-svgrepo-com.svg")
                        .default
                    }
                    alt="not_found"
                  />
                </div>

                <h6 className="title">
                  <b>Dedicated To Our Clients</b>
                </h6>

                <p className="description">
                  Client satisfaction is our number one priority. From small
                  projects to large project, both residential and commercial{" "}
                  <br /> we work like dogs to exceed all expectations!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="blue-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 d-lg-flex justify-content-center align-items-center">
                <h6 className="m-0">
                  Not sure what your project will cost? We can help!
                </h6>
                <div className="banner-btn  m-lg-0 ms-lg-3 ">
                  <a href="#">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="folio masonary">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-8  py-4 px-3 px-lg-5">
              <ResponsiveMasonry
                className="masonry-grid"
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter="10px">
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio3.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio4.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>

                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio10.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio1.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio2.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio6.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio8.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio5.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../../asset/img/portfolio/folio9.webp")}
                      alt="fire restoration"
                      className="img-fluid"
                    />
                  </div>
                </Masonry>
              </ResponsiveMasonry>
            </div>
            <div className="col-lg-4 p-lg-0">
              <div className="customers">
                <div className="item">
                  <img src={hand} alt="Top Dog Contractors" className="icon" />
                  <h4>LOCALLY OWNED</h4>
                  <p>
                    When you call us, we will be ready to answer your questions
                    and respond to your emergency.
                  </p>
                </div>
                <div className="item">
                  <img
                    src={man}
                    alt="water damage restoration"
                    className="icon"
                  />
                  <h4>RESPONSIVE</h4>
                  <p>
                    We are the restoration company in the GTA you can trust for
                    fast, reliable emergency service.
                  </p>
                </div>
                <div className="item">
                  <img src={demand} alt="mold removal" className="icon" />
                  <h4>ON-DEMAND</h4>
                  <p>
                    Ready and available 24 hours a day, 7 days a week to cleanup
                    and restore your property.
                  </p>
                </div>
                <div className="item">
                  <img
                    src={cert}
                    alt="storm damage restoration"
                    className="icon"
                  />
                  <h4>CERTIFIED</h4>
                  <p>
                    Our restoration company is IICRC certified and our
                    technicians are trained and experienced.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 className="heading4">Certifications</h4>
              <h2 className="heading2">We Are Certified in Our Expertise</h2>

              <p className="about-para mt-4">
                Top Dog Contractors works with top-leading experts in the home
                improvement industry who write, edit, review, and contribute to
                our cost guides, articles, and trends reports. This has made our
                cost information and home improvement articles serve as a go-to
                reference for major media outlets and other reference sites.
              </p>

              <div className="banner-btn about-btn mt-5">
                <NavLink to="/contact">Contact us</NavLink>
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0 d-md-flex justify-content-center align-items-center">
              <ul className="cert-ul">
                <li>
                  <img
                    src={require("../../asset/img/certifications/1.webp")}
                    alt="Top Dog Contractors"
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src={require("../../asset/img/certifications/3.png")}
                    alt="Top Dog Contractors"
                    className="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src={require("../../asset/img/certifications/remodel_certified.webp")}
                    alt="Top Dog Contractors"
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
