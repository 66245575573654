import React, { useEffect, useState } from "react";
import "./condo.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bannerImg from "../../../asset/img/services/condo-banner.jpg";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Condo = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

  const heading = "Condo Remodeling & Renovation";
  const content =
    "Are you a condo owner in Greater Triangle Area and Beyond ? Even though your exterior walls are attached to other dwellings, you can still renovate the interior.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
                Condo Remodeling in Greater Triangle Area, North Carolina
              </h1>

              <p>
                Are you a condo owner in Greater Triangle Area and Beyond
? Even though your exterior
                walls are attached to other dwellings, you can still renovate
                the interior. Whether you prefer traditional design, modern
                styling, or something in between, the team at Top Dog Contractors
                can help. We have experience updating individual rooms and
                completing complicated whole-condo renovations. We stand by our
                work and ability to assist our clients throughout the condo
                renovation process using quality materials while providing
                quality workmanship and service. Let us navigate the intricate
                design/build process so you can sit back, relax, and watch the
                project unfold before your eyes.
              </p>

              <h2>
                <b>
                  For Condo Renovations or Remodeling you can trust in Greater
                  Triangle Area, call{" "}
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>{" "}
                  to get started.
                </b>
              </h2>
              <div className="side-feature mt-3">
                <h6>
                  Condo Designing in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Bathroom renovations</li>
                  <li>Kitchen renovations</li>
                  <li>Primary suite renovations</li>
                  <li>Home additions</li>
                  <li>Floor plan alterations</li>
                  <li>Basements and attics</li>
                  <li>Design</li>
                  <li>Drafting</li>
                  <li>Permit application</li>
                  <li>Project management</li>
                  <li>Construction</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>
              
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>Reasons To Renovate Your Condo</h5>
                <p>
                  If you have lived in your condo for a long time, you may be
                  ready for a change. Should you move or renovate? Here are the
                  top reasons why renovating could be the right decision:
                </p>
                <ul className="service-ul">
                  <li>
                    <b>You love where you live </b> and don’t want to leave the
                    neighbourhood.
                  </li>
                  <li>
                    <b>You want personalized upgrades </b>or unique features
                    that you might not find if you move.
                  </li>
                  <li>
                    <b>You’re ready to modernize </b> your condo.
                  </li>
                  <li>
                    <b>You want to make your space more functional </b> by
                    adding storage space, adjusting the size of specific rooms,
                    or more.
                  </li>
                  <li>
                    <b>You wish to be involved </b> in the decision-making
                    process to make sure your home fits your exact needs.
                  </li>
                  <li>
                    {" "}
                    <b>Renovating is often less costly</b> than moving.
                  </li>
                  <li>
                    <b>Renovating may provide a better return on investment </b>{" "}
                    if you do end up selling your condo someday.
                  </li>
                </ul>

                <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call{" "}
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a>{" "}
                    to get started.
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/home/home-mockup.webp")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/bathroom/bathroom.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3   ">
              <img
                src={require("../../../asset/img/services/home/quote.jpg")}
                alt="Home Remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
                Whether your renovation involves one room or the entire house,
                you want a team that boasts keen attention to detail and
                preparedness for any surprises that could arise. Approach us
                with nothing more than a problem that needs solving, and we’ll
                get to work designing and delivering a solution.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                <b>
                  Schedule your consultation with Top Dog Contractors, call
                  <a href="tel:919-373-2882" className="phone">
                    {" "}
                    919-373-2882{" "}
                  </a>
                  to get started.
                </b>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Condo Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/condo/co2.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Condo Remodeling Gallery</h2>
                  <p>
                    Topdog Condo remodeling and renovation gallery in the region
                    of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/condo/co1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/condo/co3.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k6.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/condo/co4.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Condo Remodeling Gallery</h2>
                  <p>
                    Topdog Condo remodeling and renovation gallery in the region
                    of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/condo/co5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in6.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in7.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b12.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b13.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Condo;
