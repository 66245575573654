import React, { useEffect, useState } from "react";
import "./home.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bannerImg from "../../../asset/img/services/home-banner.jpg";
import RemBanner from "../../../components/service-banner/RemBanner";
import Sidebar from "../../../components/sidebar/Sidebar";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);

  const heading = "Whole Home Remodeling & Renovation";
  const content =
    "Are you interested in modernizing your interior? Perhaps you want your home to better suit your aesthetic tastes, or maybe it no longer fits your needs​, Top Dog Contractors is here for you.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">
               Whole Home Remodeling in Greater Triangle Area, North Carolina
              </h1>

              <p>
              Are you interested in modernizing your interior? Perhaps you want your home to better suit your aesthetic tastes, or maybe it no longer fits your needs. Whatever the case, the experienced, knowledgeable team at Top Dog Contractors is here for you.
              </p>
              <p>
              We offer a complete renovation experience from start to finish, with a commitment to outstanding quality and on-time and on-budget completion. Our team is dedicated to going above and beyond and to guarantee your satisfaction.
              </p>
              <h2>
                <b>
                  For Home Renovations or Remodeling you can trust in Greater Triangle
                  Area, call{" "}
                  <a href="tel:919-373-2882" className="phone">
                    919-373-2882
                  </a>{" "}
                  to get started.
                </b>
              </h2>
              <div className="side-feature mt-3">
                <h6>
                  Home Designing in Greater Triangle Area, North Carolina
                </h6>
                <ul>
                  <li>Bathroom renovations</li>
                  <li>Kitchen renovations</li>
                  <li>Primary suite renovations</li>
                  <li>Home additions</li>
                  <li>Floor plan alterations</li>
                  <li>Basements and attics</li>
                  <li>Design</li>
                  <li>Drafting</li>
                  <li>Permit application</li>
                  <li>Project management</li>
                  <li>Construction</li>
                  
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
              <Sidebar/>
            
            </div>
          </div>
        </div>
      </div>

      <div className="water-content kitchen-content">
        <div className="container">
          <div className="row pt-2">
            <div className=" col-lg-6 order-last order-lg-first mt-3">
              <div className="mb-5">
                <h5>REASONS TO RENOVATE YOUR WHOLE HOME</h5>
                <p>A whole-home remodel can help you accomplish these goals:
                </p>
                <ul>
                  <li>
                  <b>Age in place: </b> Relocating the main suite to the ground floor, widening doors and hallways, and replacing exterior stairs with ramps are updates that help accommodate your changing needs as you age.
                  </li>
                  <li>
                  <b>Modernize: </b> If your home is outdated, a whole-home remodel can bring it into the 21st century. We can update your home’s layout, finishes, and fixtures to suit your tastes and lifestyle.
                  </li>
                  <li>
                  <b>Improve functionality: </b> If your home no longer meets your needs, a whole-home remodel can help. We can reconfigure your floor plan to better suit your lifestyle and make your home more functional.
                  </li>
                  <li>
                  <b>Create a more eco-friendly design: </b> Going green is not a passing fad. Adding sustainable features to your home will reduce waste, breathe cleaner air, and lower your utility costs.
                  </li>
                  <li>
                  <b>Add more space: </b> With a whole-home renovation, you can get the extra square footage you need for your growing family without moving.
                  </li>
                </ul>

                <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call{" "}
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a>{" "}
                    to get started.
                  </b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last mt-3">
              <img
                src={require("../../../asset/img/services/home/home-mockup.webp")}
                alt="kitchen remodeling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-6 mt-3">
              <img
                src={require("../../../asset/img/services/bathroom/bathroom.webp")}
                alt="kitchen renovation"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-3   ">
              
               <img src={require("../../../asset/img/services/home/quote.jpg")} alt="Home Remodeling" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="choose-us py-5">
        <div className="container">
          <div className="row no-gutter">
            <div className="col-lg-6 mt-3 d-flex align-items-center  ">
              <h1 className="process-heading    ">
                <span>Why Choose</span> Top Dog Contractors
              </h1>
            </div>

            <div className="col-lg-6 mt-3">
              <p>
              Whether your renovation involves one room or the entire house, you want a team that boasts keen attention to detail and preparedness for any surprises that could arise. Approach us with nothing more than a problem that needs solving, and we’ll get to work designing and delivering a solution.
              </p>

              <p>
                We deliver superior customer service, our designs are
                award-winning, and we pay meticulous attention to the details to
                ensure a stellar final product.
              </p>

              <h2>
                  <b>
                    Schedule your consultation with Top Dog Contractors, call 
                    <a href="tel:919-373-2882" className="phone">
                      919-373-2882
                    </a> 
                    to get started.
                  </b>
                </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-bg white">
        <div className="container">
          <h1 className="mb-4">Bathroom Remodeling Gallery</h1>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpen(true)}>
                <img
                  src={require("../../../asset/img/services/home/in1.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Home Remodeling Gallery</h2>
                  <p>
                    Topdog Home remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/home/in1.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in2.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in3.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k4.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/kitchen/k6.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>

            <div className=" col-md-6 mt-2">
              <div className="projectImg" onClick={() => setOpenOne(true)}>
                <img
                  src={require("../../../asset/img/services/bathroom/b7.webp")}
                  className="img-fluid pointer"
                  alt="projectImg"
                />
                <div className="overlay">
                  <h2>Home Remodeling Gallery</h2>
                  <p>
                    Topdog Home remodeling and renovation gallery in the
                    region of Greater Triangle Area, North Carolina
                  </p>
                </div>
                <Lightbox
                  open={openOne}
                  close={() => setOpenOne(false)}
                  slides={[
                    {
                      src: `${require("../../../asset/img/services/bathroom/b8.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in5.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in6.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/home/in7.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b12.webp")}`,
                    },
                    {
                      src: `${require("../../../asset/img/services/bathroom/b13.webp")}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
