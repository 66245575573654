import React, { useEffect } from "react";
import "./water.scss";

import { damageGallary } from "../../../utils/service";
import bannerImg from "../../../asset/img/services/water-dummy.webp";
import ReBanner from "../../../components/service-banner/ReBanner";
import Sidebar from "../../../components/sidebar/Sidebar";
const Water = () => {
  const heading = "TIME MATTERS WHEN YOU HAVE WATER DAMAGE.";
  const content =
    "Water damage may not look very serious, but the damage can  escalate quickly and is often preventable. Immediate action can minimize your property damage.​";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReBanner heading={heading} content={content} img={bannerImg} />

      <div className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first">
              <h1 className="water-heading-1">WATER DAMAGE RESTORATION</h1>

              <p>
                Providing 24/7 Emergency Services we are here to support you
                every step of the way in restoring your property back to
                normal.​ Timing is critical to reducing further water damage.
                Our IICRC trained and certified technicians are well qualified
                to perform a variety of water damage restoration services.​
              </p>
              <p>
                Our priority is to help you return to normalcy as quickly as
                possible. Ready to help, any time of day or night - our team is
                qualified to assist you in any water damage situation
                imaginable. With our high-tech tools and drying equipment for
                for removing moisture and humidity, we make it our goal to
                thoroughly clean and restore your property with as little
                disruption as possible.
              </p>
              <p>
                Water can cause significant damage to homes and properties, even
                in small amounts. Its infiltration can lead to extensive
                structural damage and mold growth. Mold exposure often causes
                respiratory problems and exacerbates existing respiratory
                conditions. Thus, the risks of being exposed to mold in your
                home or business should not be ignored. A quick response is an
                important part of any successful water damage cleanup. It is
                imperative to properly dry your property quickly, as when water
                sits in place, the risk of microbial growth and damage to the
                property contents increase drastically.
              </p>

              <div className="side-feature">
                <h6>
                  Water Damage Restoration and Repair in Greater Triangle Area,
                  North Carolina
                </h6>
                <ul>
                  <li>Emergency Flood and Water Cleanup Service</li>
                  <li>Emergency Plumbing Repair and Leak Detection</li>
                  <li>Basement Water Damage and Sewage Cleanup</li>
                  <li>Water Removal and Water Extraction</li>
                  <li>Water Damage Sanitation, Drying and Dehumidification</li>
                  <li>Mold Cleaning and Odour Removal</li>
                  <li>Contents Total Loss Reports</li>
                  <li>Contents Restoration, Handling, Inventory and Storage</li>
                  <li>Temporary Power and Climate Control Solutions</li>
                  <li>Temporary Business Relocation Services</li>
                  <li>Major Loss and Commercial Water Damage Restoration</li>
                  <li>Full-Service General Contracting and Reconstruction</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 order-first order-lg-last">
             <Sidebar/>
            </div>
          </div>
        </div>
      </div>

      <div className="get-started">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="left">
                <h1>GET STARTED</h1>
                <p className="step">WITH OUR EASY 3 STEP RECOVERY PROCESS.</p>
                <p className="arival">
                  ​Half Hour Arrival Time. Insurance Approved.
                </p>

                <a href="tel:919-373-2882">Call: 919-373-2882</a>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="left right">
                <h3>Step 1- Call Us</h3>
                <p>
                  Our team is ready to respond 24 hours a day, 365 days of the
                  year. Even if you just have questions, we are available to
                  help you.
                </p>
                <h3>STEP 2 - Immediate Response</h3>
                <p>
                  Our trained, certified and uniformed technicians will respond
                  within <b>half hour</b> to inspect your property and determine
                  a plan of action that suits your needs.
                </p>

                <h3>STEP 3 - Immediate Action</h3>

                <p>
                  We can take immediate action to begin the recovery process
                  right away. This will minimize further property damage and
                  save you money. ​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="water-content">
        <div className="container">
          <h5>The 4 Classes of Water Damage</h5>
          <p>
            The 4 classes of water damage are determined by the{" "}
            <a href="https://www.flipsnack.com/iicrcannualreport/iicrc-snapshot/full-view.html">
              {" "}
              Institute of Inspection Cleaning and Restoration Certification
              (IICRC)
            </a>{" "}
            S500 standard. These classes of water loss refer to the amount of
            water and the anticipated rate of evaporation based on the amount
            and type of wet material in the affected area. The ANSI/IICRC S500
            standard describes the procedures to be followed and the precautions
            to be taken when performing water damage and sewage damage
            restoration of residential and commercial property, and their
            contents
          </p>
          <div className="row pt-2">
            <div className="col-md-6 mt-3">
              <h5>Class 1: Slow Rate of Evaporation</h5>
              <p>
                Class 1 water damage affects only part of a room with minimal
                water absorption into contents and building materials such as
                carpet, wood flooring, drywall and framing. Class 1 is the least
                amount of flooding, absorption and evaporation and is typically
                contained to affecting only part of a room or floor.
              </p>
            </div>

            <div className="col-md-6 mt-3 ">
              <h5>Class 2: Fast Rate of Evaporation</h5>
              <p>
                Class 2 water losses affect the room from wall-to-wall and have
                damaged furniture and materials like drywall, wood flooring,
                carpet and underpad. Capillary action resulting in absorption or
                "wicking" of water up drywall is typically less than 2 feet
                high. Moisture can remain in building materials such as carpet,
                drywall, framing and concrete/brick resulting in mold growth if
                not properly cleaned and dried.
              </p>
            </div>

            <div className="col-md-6 mt-3">
              <h5>Class 3: Fastest Rate of Evaporation</h5>
              <p>
                Class 3 water losses typically come from above (ex: roof,
                ceiling or upstairs) resulting in wet insulation, collapsed or
                sagging ceilings, wet walls, flooring, and subfloor. Class 3
                involves the greatest amount of water, absorption and
                evaporation. Time matters when you are dealing with a Class 3
                water damag
              </p>
            </div>
            <div className="col-md-6 mt-3">
              <h5>Class 4: Specialty Drying Situations</h5>
              <p>
                Class 4 water damages may require longer drying times and
                circumstantial approaches to restoration and repair. Specialty
                drying situations involving saturated structural materials,
                oftentimes due to high-volume water damages, catastrophic
                flooding or extreme weather events which can submerge parts of a
                building.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="banner-background white py-5">
        <div className="container">
          <h1 className="mb-4">WATER DAMAGE PHOTO GALLERY</h1>
          <div className="row">
            {damageGallary.map((item, index) => (
              <div
                className="col-6 col-sm-4-col-md-3 col-lg-3 mt-2"
                key={index}
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="img-fluid map-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Water;
