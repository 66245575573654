import React from "react";
import "./footer.scss";
import { Link, NavLink } from "react-router-dom";
import {
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
  BsGeoAlt,
  BsTelephone,
  BsWhatsapp,
} from "react-icons/bs";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
const Footer = () => {
  return (
    <div className="footer">


<a href="tel:919-373-2882" className="phone-fixed">
<FiPhoneCall className="icon"/>
</a>
      
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="footer__about">
              <Link to="/">
                <img
                  src={require("../../asset/img/logo.png")}
                  alt="Top Dog Contractors"
                />
              </Link>

              <p className="mt-4 footer-para">
              At Top Dog Contractors, we are committed to providing exceptional service and customer satisfaction every step of the way. Our team of experts is dedicated to restoring your property to its pre-damage condition quickly and efficiently, so you can get back to your normal life.
              </p>
            </div>
          </div>

          <div className="col-lg-4  d-md-flex justify-content-between d-lg-block mt-4 mt-lg-0 socialIcons ">
            
            <div className="social">
              <div className="icon-container">
                <BsGeoAlt className="icon" />
              </div>

              <div>
                <h5>Location:</h5>
                <p>
                 555 Fayetteville Street, Suite 201,
                  <br />
                  Raleigh, NC 27601
                </p>
              </div>
            </div>
            
            

            <div className="social">
              <div className="icon-container">
                <BsFillEnvelopeFill className="icon" />
              </div>

              <div>
                <h5>Email:</h5>
                <p>
                  <a href="mailto: info@ttopDog.construction">
                    info@topdog.contractors
                  </a>
                </p>
              </div>
            </div>

            <div className="social mt-3">
              <div className="icon-container">
                <BsTelephone className="icon" />
              </div>

              <div>
                <h5 >Call:</h5>
                <p>
                  <a href="tel:919-373-2882">919-373-2882</a>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0  pt-4 pt-md-0">
            <ul className="footer-ul">
                <li>
                <NavLink to="/" >Home</NavLink> 
              </li>

              <li>
                <NavLink to="/services" >Services</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/water-damage-restoration" >Water Restoration</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/mold-remediation">Mold Restoration</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/fire-damage-restoration"> Fire Restoration</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/biohazard-cleanup"> Bio-Hazard Cleanup</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/air-duct-cleaning">Air Duct Cleaning</NavLink> 
              </li>
              <li>
                <NavLink to="/restoration-and-cleaning/storm-damage-restoration">Storm Damage Restoration</NavLink> 
              </li>
              <li>
                <NavLink to="/remodeling-and-renovation/kitchen-remodeling">kitchen Work</NavLink> 
              </li>
              <li>
                <NavLink to="/remodeling-and-renovation/bathroom-remodeling">Bathroom Work</NavLink> 
              </li>
              <li>
                <NavLink to="/remodeling-and-renovation/home-remodeling">Home Work</NavLink> 
              </li>
              <li>
                <NavLink to="/remodeling-and-renovation/condo-remodeling">Condo Work</NavLink> 
              </li>
              <li>
                <NavLink to="/remodeling-and-renovation/basement-remodeling">Basement Work</NavLink> 
              </li>

              <li>
                <NavLink to="/remodeling-and-renovation/master-suite-remodeling">Master Suit Work</NavLink> 
              </li>
              <li>
                <NavLink to="/commercial-services">commercial</NavLink> 
              </li>

              
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>


            <div className="social-icons">
              <ul>
                <li>
                  <a href="#">
                    <BsWhatsapp/>
                  </a>
                </li>

                <li>
                  <a href="">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaTwitter/>
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Footer;
