import React, { useEffect } from "react";
import "./main.scss";
import { Link, NavLink } from "react-router-dom";
import { BsQuote } from "react-icons/bs";
import  { remodeling } from "../../../utils/service"; 
import bannerImg from "../../../asset/img/services/remodel.jpeg";
import Sidebar from "../../../components/sidebar/Sidebar";
import RemBanner from "../../../components/service-banner/RemBanner";
const Remodeling = () => {
  const heading = "OUR experts can handle projects of any size or complexity ";
  const content ="The payout from an insurance claim could cover much of the costs associated with a remodeling or renovation project. Insurance claim or not, contact us for your remodeling or renovation needs.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <RemBanner heading={heading} content={content} img={bannerImg} />
      <div className="topdog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2>We Are Here To Serve You for your Remodeling and Renovation Needs</h2>
              <h4>Top Dog Contractors is here to help</h4>
              <p>Remodeling and Renovation Services is your trusted partner for transforming your property into a beautifully renovated and functional space. As a leading full-service company in the Greater Triangle Area and Beyond, we have built a reputation for delivering top-quality remodeling and renovation solutions that exceed expectations.
              </p>
              <p>With extensive experience in the industry, we understand the unique challenges and complexities involved in remodeling and renovation projects. Our team of skilled professionals is well-versed in the latest design trends, construction techniques, and building codes. We work closely with you to bring your vision to life while ensuring structural integrity and functionality.
              </p>

              <p>The payout from an insurance claim could cover a lot of the costs associated with a remodeling or renovation project. For example, items such as demolition, drywall, painting, flooring, and more may be covered by your insurance claim. Such an occurrence could make that big project much more affordable. If you're beginning a restoration claim and have ever thought about remodeling or renovation, give us a call today. <a className="text-decoration-none" href="tel:(919) 373-2882">(919) 373-2882</a> </p>
              

              <div className="review">
                <div className="testimonial">
                  <p className="position-relative pt-2">
                    <BsQuote className="quote-left " />
                    Remodeling and Renovation Services exceeded expectations with their outstanding craftsmanship and attention to detail. They transformed our outdated kitchen into a stunning, modern space. Highly recommended for top-quality remodeling solutions.
                    <BsQuote className="quote-right" />
                  </p>
                  <p>[David L]</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center align-itmes-center">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>

      <div className="all-services" id="restoration-and-cleaning">
        <div className="container">
        <h4 className="servic-heading-4">Our Services</h4>
          <h1 className="service-heading serivce-page mt-5" >
            Helping You With Your Remodeling and Renovation Needs
          </h1>
          
 
          <div  className="row pt-5">
            {remodeling.map((item, index) => {
              return (
                <div key={index} className="col-md-6 col-lg-4 mb-5">
                  <NavLink to={item.link}>
                    <div className="custom-card">
                      <div className="card-image">
                        <img
                          src={item.img}
                          alt={item.alt}
                          className="img-fluid"
                        />
                      </div>
                      <div className="card-content">
                        <h4>{item.title}</h4>

                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
          
        </div>
      </div>

      
      <div className="count">
        <div className="container">
          <h1 className="h1">why select us</h1>
          <p className="p">
            Restoration is an IICRC certified restoration company in Greater
            Triangle Area that specializes in restoring residential and
            commercial properties damaged by fire, water, mold, wind and
            vandalism. We are a privately owned full-service general contracting
            company providing construction, renovation and environmental
            services.
          </p>

          <h3 className="h3">Emergency Cleanup and Restoration Services</h3>
          <ul className="count-ul">
            <li>24/7 Emergency Response</li>
            <li>Half Hour Arrival Time</li>
            <li>Advanced Equipment & Techniques</li>
            <li>Professional Communication & Documentation</li>
            <li>Fast Cleanup for Peace of Mind</li>
          </ul>

          <h3 className="h3">Certified, Qualified and Professional</h3>
          <ul className="count-ul">
            <li>Insurance Claim Specialists</li>
            <li>Fast, Friendly and Reliable Service</li>
            <li>IICRC Trained and Certified Technicians</li>
            <li>Patient & Compassionate Staff</li>
            <li>Licensed and Insured</li>
          </ul>

          <p>
            Whether you have experienced property damage due to flooding or
            other
            <Link to="/services/water-damage-restoration">
              water damage
            </Link> , <Link to="/services/fire-damage-restoration">fire</Link> ,
            <Link to="/services/storm-damage-restoration">storm damage</Link> or
            you are concerned about environmental contaminants such as mold or
            asbestos, we are committed to providing prompt emergency restoration
            services 24 hours a day, 7 days a week, 365 days a year.
          </p>
        </div>
      </div>
    </>
  );
};

export default Remodeling;
